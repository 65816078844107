import React from "react";

const UserList = () => {
    return (
        <section>

        </section>
    )
}

export default UserList;