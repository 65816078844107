import React, { useState,useEffect } from 'react';
import {
    Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, IconButton, TextField, Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Button,
} from '@mui/material';
import { Edit as EditIcon, Save as SaveIcon } from '@mui/icons-material';
import { Editor } from '@tinymce/tinymce-react';
import DOMPurify from 'dompurify';

const EditableTable = ({ data, onUpdate }) => {
    const [open, setOpen] = useState(false);
    const [editField, setEditField] = useState({ id: null, field: null });
    const [formData, setFormData] = useState(data);
    const [editingContent, setEditingContent] = useState('');

    const handleEditClick = (id, field, content) => {
        // console.log("edit",id,field,content)
        setEditField({ id, field });
        if (field === "answer") {
            id && setOpen(true);
            setEditingContent(content);
        } else {
            setEditingContent(content);
        }
    };

    useEffect(()=>{
        setFormData(data)
    },[data])

    const handleCloseEditor=(id,field)=>{
        setEditField({ id: null, field: null });
        setOpen(false)

    }

    const handleSaveClick = (id, field) => {
        setEditField({ id: null, field: null });
        onUpdate(id, field, editingContent);
    };

    return (
        <TableContainer component={Paper}>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>Question</TableCell>
                        <TableCell>Do You Know</TableCell>
                        <TableCell>Answer</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {formData.map(row => (
                        <TableRow key={row._id}>
                            <TableCell>
                                {editField.id === row._id && editField.field === 'question' ? (
                                    <>
                                        <TextField
                                            value={editingContent}
                                            onChange={(e) => setEditingContent(e.target.value)}
                                        />
                                        <IconButton onClick={() => handleSaveClick(row._id, 'question')}>
                                            <SaveIcon />
                                        </IconButton>
                                    </>
                                ) : (
                                    <>
                                        {row.question}
                                        <IconButton onClick={() => handleEditClick(row._id, 'question', row.question)}>
                                            <EditIcon />
                                        </IconButton>
                                    </>
                                )}
                            </TableCell>

                            <TableCell>
                                {editField.id === row._id && editField.field === 'doYouKnow' ? (
                                    <>
                                        <TextField
                                            value={editingContent}
                                            onChange={(e) => setEditingContent(e.target.value)}
                                        />
                                        <IconButton onClick={() => handleSaveClick(row._id, 'doYouKnow')}>
                                            <SaveIcon />
                                        </IconButton>
                                    </>
                                ) : (
                                    <>
                                        {row.doYouKnow}
                                        <IconButton onClick={() => handleEditClick(row._id, 'doYouKnow', row.doYouKnow)}>
                                            <EditIcon />
                                        </IconButton>
                                    </>
                                )}
                            </TableCell>

                            <TableCell>
                                {editField.id && editField.id === row._id && editField.field === 'answer' ? (
                                    <>
                                        <Dialog open={open} onClose={() => setOpen(false)} fullWidth>
                                            <DialogTitle>Edit {editField.field}</DialogTitle>
                                            <DialogContent>
                                                <Editor
                                                    apiKey="wpc3883gy9wwm18yhjxowq2ic75qi67u0b0h3vi9eydn69mm"
                                                    init={{
                                                        height: 500,
                                                        menubar: true,
                                                        plugins: [
                                                            'advlist autolink lists link image charmap print preview anchor',
                                                            'searchreplace visualblocks code fullscreen',
                                                            'insertdatetime media table paste code help wordcount',
                                                        ],
                                                        toolbar:
                                                            'undo redo | formatselect | bold italic backcolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat | help | table',
                                                    }}
                                                    value={editingContent}
                                                    onEditorChange={(newContent) => setEditingContent(newContent)}
                                                />

                                                {/* <IconButton onClick={() => handleSaveClick(row.response.id, 'answer')}>
                                                    <SaveIcon />
                                                </IconButton> */}
                                            </DialogContent>
                                            <DialogActions>
                                                <Button onClick={() =>handleCloseEditor(row._id, 'answer')}>Cancel</Button>
                                                <Button onClick={() => handleSaveClick(row._id, 'answer')} color="primary" startIcon={<SaveIcon />}>
                                                    Save
                                                </Button>
                                            </DialogActions>
                                        </Dialog>
                                    </>
                                ) : (
                                    <>
                                        {/* {row.answer?.slice(0, 100)}... */}
                                        <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(row.answer?.slice(0, 100) + '...') }} />
                                        <IconButton onClick={() => handleEditClick(row._id, 'answer', row.answer)}>
                                            <EditIcon />
                                        </IconButton>
                                    </>
                                )}

                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer >
    );
};

export default EditableTable;
