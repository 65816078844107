import React from 'react'
import { sideBarLink } from "../../data/HomepageData/queriesData";

import { useSelector, useDispatch } from 'react-redux';
import { addFilter, removeFilter, clearAllFilters } from '../../Slices/filterSlice';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

const MobileSidebarLayout = ({handleCollapse,filters,setSearchText,isCollapse,handleCloseCategory}) => {
    const dispatch = useDispatch();

    return (
        <div
            className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 "
            onClick={handleCloseCategory}
        >
            <div
                // onClick={(e) => e.stopPropagation()}
                className="absolute top-2 w-[350px] shadow-[0px_100px_80px_0px_#00000033] bg-white rounded-lg p-4 my-10 overflow-y-scroll h-[80vh]"
            >
                {sideBarLink.map((section, index) => (
                    <div key={index} className="m-1 h-auto border-b-[1px] border-b-[#dadada]">
                        <button
                            className="flex justify-between items-center w-full  p-[0.25em] bg-white rounded-lg font-semibold transition-all duration-500 ease-in-out"
                            onClick={() => handleCollapse(index)}
                        >
                            <span className="text-[1.2rem]">{section.title.label}</span>
                            <KeyboardArrowDownIcon />
                        </button>
                        <div
                            className={`transition-max-height  duration-500 ease-in-out ${isCollapse[index] ? 'max-h-[500px]' : 'max-h-0 overflow-hidden'}`}
                        >
                            <div className="rounded-lg mt-1 p-1">
                                {section.labels.map((label, labelIndex) => (
                                    <label key={labelIndex} className="block text-[1rem]">
                                        <input
                                            type="checkbox"
                                            className="mr-2"
                                            onChange={(e) => {
                                                const value = label.value; // Filter value
                                                const filterType = section.title.value; // Filter type
                                                setSearchText("")
                                                if (e.target.checked) {
                                                    dispatch(addFilter({ filterType, value }));
                                                } else {
                                                    dispatch(removeFilter({ filterType, value }));
                                                }
                                            }}
                                            checked={filters[section.title.value]?.includes(label.value) || false}
                                        />
                                        {label.title}
                                    </label>
                                ))}
                            </div>
                        </div>
                    </div>
                ))}

            </div>
        </div>
    )
}

export default MobileSidebarLayout
