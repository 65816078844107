import React, { useEffect, useState } from 'react';
import { Drawer, IconButton, Box, Button, Avatar, Typography } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import Navbar from "../Components/Navbar";
import Sidebar from "../Components/Dashbaoard/UserDashboard/Sidebar";
import QueryCard from "../Components/Dashbaoard/UserDashboard/QueryCard";
import axios from 'axios';

import USer from "../assests/User.png";
import { baseUrl } from '../utils/baseUrl';
import { useNavigate } from 'react-router-dom';

const UserDashboard = () => {
    const navigate=useNavigate()
    const [userAssistanceData, setUserAssistanceData] = useState([])
    const [userProfile, setUserProfile] = useState()
    const profileData = [
        { title: "Your Profile", content: "A Working Woman" },
        { title: "Reason To Visit Finright", content: "Seeking Assistance On Issues I Am Facing" },
        { title: "Your Style", content: "The DIY Guy - I Make My Own Financial Decisions" },
        { title: "Your Priorities", content: "Reduce My Tax Burden, Optimizing My Finances, Protection Against Any Mishaps" }
    ];
    const [drawerOpen, setDrawerOpen] = useState(false);

    // Toggle drawer on and off
    const toggleDrawer = (open) => () => {
        setDrawerOpen(open);
    };

    const handleLogOut=()=>{
        window.localStorage.removeItem("token")
        window.localStorage.removeItem("user")
        navigate("/")
    }

    useEffect(() => {
        const fetchAllAssistanceRequest = async () => {
            const token = window.localStorage.getItem("token")
            try {
                const response = await axios.get(`${baseUrl}/user/my-assistance`,{
                    headers:{
                        "Authorization":`Bearer ${token}`                    }
                });
                console.log("response", response.data)
                setUserAssistanceData(response?.data?.assistanceLogs);
            } catch (error) {
                console.error('Error fetching questions:', error);
            }
        }

        fetchAllAssistanceRequest()

    }, [])

    return (
        <div className="w-[100vw] relative">
            <Navbar />
            <p className="absolute top-[60px] right-4 block lg:hidden text-[#313D4F] text-[1.2rem]" onClick={handleLogOut}>Log Out</p>
            <section>
                <div className="min-h-screen lg:w-[80vw] w-[100vw] mx-auto">
                    <div className="flex lg:justify-end justify-between items-center p-4 font-inter gap-5">
                        {/* MenuIcon button to toggle the Drawer */}
                        <span className='lg:hidden block'>
                            <IconButton onClick={toggleDrawer(true)} edge="start">
                                <MenuIcon />
                            </IconButton>
                        </span>

                        {/* Drawer Component */}
                        <Drawer anchor="left" open={drawerOpen} onClose={toggleDrawer(false)}>
                            <Box
                                sx={{
                                    width: 250,
                                    padding: '1rem',
                                    bgcolor: 'white',

                                }}
                                role="presentation"
                                onClick={toggleDrawer(false)}
                                onKeyDown={toggleDrawer(false)}
                            >

                                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                    <Box
                                        sx={{
                                            border: '10px solid #702DFF',
                                            borderRadius: '50%',
                                            width: '7rem',
                                            height: '7rem',
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                        }}
                                    >
                                        <Avatar
                                            src={USer}
                                            alt="profile"
                                            sx={{
                                                width: '6rem',
                                                height: '6rem',
                                                border: '5px solid #FFFFFF',
                                            }}
                                        />
                                    </Box>
                                    <Box sx={{ textAlign: 'center' }}>
                                        <Typography variant="h6" sx={{ marginTop: '1rem', color: '#202020', fontWeight: '600' }}>
                                            Ashirwad Tomar
                                        </Typography>
                                        <Typography sx={{ color: '#7E7E7E', fontWeight: '600', fontSize: '1.2rem' }}>0987654321</Typography>
                                        <Typography sx={{ color: '#7E7E7E' }}>Tomarnjwdbfjb@gmail.com</Typography>
                                    </Box>
                                </Box>

                                <Box sx={{ marginTop: '1.5rem', padding: '1rem' }}>
                                    {profileData.map((item, index) => (
                                        <Box key={index} sx={{ marginTop: '1rem' }}>
                                            <Typography variant="h6" sx={{ fontWeight: '600', color: '#202020', fontSize: '1rem' }}>
                                                {item.title}
                                            </Typography>
                                            <Typography sx={{ color: '#5F5F5F', fontSize: '0.8rem' }}>{item.content}</Typography>
                                        </Box>
                                    ))}
                                </Box>
                            </Box>
                        </Drawer>

                        <button className="hidden lg:block text-[#313D4F]" onClick={handleLogOut}>Log Out</button>
                        <button
                            style={{ border: "1px solid #D8D8D8" }}
                            className="bg-[#006BFF] text-white px-4 py-1 rounded-lg"
                        >
                            Ask More Queries
                        </button>
                    </div>

                    <div className="flex flex-col md:flex-row p-4 space-y-6 md:space-y-0 md:space-x-6 lg:w-[80vw] w-[100vw] mx-auto">
                        <Sidebar img={USer} />
                        <div className="w-full md:w-3/4 space-y-6">
                            <QueryCard userAssistanceData={userAssistanceData} />
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
};

export default UserDashboard;
