import React, { useState, useEffect } from 'react';
import axios from 'axios';
import toast, { Toaster } from 'react-hot-toast';
import { baseUrl } from '../../utils/baseUrl';

const FilterEditor = () => {
    const [filterType, setFilterType] = useState('');
    const [action, setAction] = useState('');
    const [filterValue, setFilterValue] = useState('');
    const [selectedQuestion, setSelectedQuestion] = useState('');
    const [questionList, setQuestionList] = useState([]);
    const [filterOptions, setFilterOptions] = useState([]);
    const [editableFilters, setEditableFilters] = useState({});
    const [errors, setErrors] = useState({ question: '', filterType: '', action: '' });

    const validFilterTypes = ['category', 'goals', 'profession', 'financialStatus', 'experience'];
    const validActions = ['add', 'remove', 'update'];

    useEffect(() => {
        const fetchQuestions = async () => {
            try {
                const response = await axios.get(`${baseUrl}/admin/question-list`);
                setQuestionList(response?.data?.questions);
            } catch (error) {
                console.error('Error fetching questions:', error);
            }
        };

        fetchQuestions();
    }, []);

    useEffect(() => {
        if ((action === 'remove' || action === 'update') && filterType && selectedQuestion) {
            const fetchFilterOptions = async () => {
                try {
                    const response = await axios.get(
                        `${baseUrl}/search-page-query/get-filters-for-removal/${selectedQuestion}?filterType=${filterType}`
                    );
                    const fetchedFilters = response?.data?.filterValues || [];
                    setFilterOptions(fetchedFilters);
                    if (action === 'update') {
                        setEditableFilters(
                            fetchedFilters.reduce((acc, filter) => {
                                acc[filter] = filter; // Initialize each filter with its current value
                                return acc;
                            }, {})
                        );
                    }
                } catch (error) {
                    console.error('Error fetching filter options:', error);
                }
            };

            fetchFilterOptions();
        } else {
            setFilterOptions([]);
            setEditableFilters({});
        }
    }, [action, filterType, selectedQuestion]);

    const validateInputs = () => {
        let isValid = true;
        const newErrors = { question: '', filterType: '', action: '' };

        if (!selectedQuestion) {
            newErrors.question = 'Question is required.';
            isValid = false;
        }
        if (!filterType) {
            newErrors.filterType = 'Filter type is required.';
            isValid = false;
        }
        if (!action.trim()) {
            newErrors.action = 'Action is required.';
            isValid = false;
        }

        setErrors(newErrors);
        return isValid;
    };

    const handleUpdateFilters = async () => {
        if (!validateInputs()) {
            return;
        }

        try {
            const payload = {
                filterType,
                action,
                filterValue: action === 'update' ? Object.values(editableFilters) : filterValue,
            };

            const response = await axios.patch(
                `${baseUrl}/search-page-query/add-remove-filters/${selectedQuestion}`,
                payload
            );

            toast.success(response.data.message);

            // Reset all states to initial values
            setFilterType('');
            setAction('');
            setFilterValue('');
            setEditableFilters({});
            setSelectedQuestion('');
            setFilterOptions([]);
            setErrors({});
        } catch (error) {
            console.error('Error updating filters:', error);
            toast.error('Error updating filters.');
        }
    };

    const handleEditableFilterChange = (key, value) => {
        setEditableFilters((prevFilters) => ({
            ...prevFilters,
            [key]: value
        }));
    };

    return (
        <div className="p-6 bg-white shadow-md rounded-md max-w-md mx-auto">
            <Toaster />
            <h2 className="text-2xl font-semibold mb-4">Manage Filters for Question</h2>

            <div className="mb-4">
                <label className="block text-sm font-medium text-gray-700 mb-2" htmlFor="question">
                    Question
                </label>
                <select
                    id="question"
                    value={selectedQuestion}
                    onChange={(e) => setSelectedQuestion(e.target.value)}
                    className={`block w-full border ${errors.question ? 'border-red-500' : 'border-gray-300'} rounded-lg p-2 focus:outline-none focus:ring focus:ring-blue-500`}
                    required
                >
                    <option value="" disabled>Select a question</option>
                    {questionList.map((q) => (
                        <option key={q._id} value={q._id}>
                            {q.question}
                        </option>
                    ))}
                </select>
                {errors.question && <p className="text-red-500 text-sm mt-1">{errors.question}</p>}
            </div>

            <div className="mb-4">
                <label className="block text-sm font-medium text-gray-700 mb-2">Filter Type</label>
                <select 
                    value={filterType} 
                    onChange={(e) => setFilterType(e.target.value)} 
                    className={`block w-full border ${errors.filterType ? 'border-red-500' : 'border-gray-300'} rounded-lg p-2`}
                >
                    <option value="">Select Filter Type</option>
                    {validFilterTypes.map((type) => (
                        <option key={type} value={type}>{type}</option>
                    ))}
                </select>
                {errors.filterType && <p className="text-red-500 text-sm">{errors.filterType}</p>}
            </div>

            <div className="mb-4">
                <label className="block text-sm font-medium text-gray-700 mb-2">Action</label>
                <select 
                    value={action} 
                    onChange={(e) => setAction(e.target.value)} 
                    className={`block w-full border ${errors.action ? 'border-red-500' : 'border-gray-300'} rounded-lg p-2`}
                >
                    <option value="">Select Action</option>
                    {validActions.map((action) => (
                        <option key={action} value={action}>{action}</option>
                    ))}
                </select>
                {errors.action && <p className="text-red-500 text-sm">{errors.action}</p>}
            </div>

            {action === 'remove' && (
                <div className="mb-4">
                    <label className="block text-sm font-medium text-gray-700 mb-2">Filter Value to Remove</label>
                    <select 
                        value={filterValue} 
                        onChange={(e) => setFilterValue(e.target.value)} 
                        className="block w-full border border-gray-300 rounded-lg p-2"
                    >
                        <option value="">Select Filter Value</option>
                        {filterOptions.map((value, index) => (
                            <option key={index} value={value}>{value}</option>
                        ))}
                    </select>
                </div>
            )}

            {action === 'update' && (
                <div className="mb-4">
                    <label className="block text-sm font-medium text-gray-700 mb-2">Edit Filter Values</label>
                    {Object.keys(editableFilters).map((key, index) => (
                        <div key={index} className="flex items-center mb-2">
                            <label className="mr-2">{key}:</label>
                            <input
                                type="text"
                                value={editableFilters[key]}
                                onChange={(e) => handleEditableFilterChange(key, e.target.value)}
                                className="border border-gray-300 rounded px-2 py-1"
                            />
                        </div>
                    ))}
                </div>
            )}

            {action !== 'update' && action !== 'remove' && (
                <div className="mb-4">
                    <label className="block text-sm font-medium text-gray-700 mb-2">Filter Value</label>
                    <input
                        type="text"
                        value={filterValue}
                        onChange={(e) => setFilterValue(e.target.value)}
                        placeholder="Enter filter value"
                        className="block w-full border border-gray-300 rounded-lg p-2"
                    />
                </div>
            )}

            <button 
                onClick={handleUpdateFilters}
                className="bg-blue-500 hover:bg-blue-600 text-white font-semibold py-2 px-4 rounded mt-4 w-full"
            >
                Submit
            </button>
        </div>
    );
};

export default FilterEditor;
