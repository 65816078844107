import React, { useState, useEffect } from 'react';
import {
    Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, IconButton,
} from '@mui/material';
import { Edit as EditIcon, Save as SaveIcon } from '@mui/icons-material';
import DeleteIcon from '@mui/icons-material/Delete';

const QuestionList = ({ data, onDelete }) => {
    const [formData, setFormData] = useState(data);

    const handleDeleteClick = (id) => {
        onDelete(id);
    };

    useEffect(() => {
        setFormData(data)
    }, [data])

    return (
        <TableContainer component={Paper}>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>Question</TableCell>
                        <TableCell>Action</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {formData.map(row => (
                        <TableRow key={row._id}>
                            <TableCell>

                                {row.question}
                                {/* <IconButton onClick={() => handleDeleteClick(row._id)}>
                                    <EditIcon />
                                </IconButton> */}
                            </TableCell>
                            <TableCell>
                                <IconButton onClick={() => handleDeleteClick(row._id)}>
                                    <DeleteIcon />
                                </IconButton>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer >
    );
};

export default QuestionList;
