import React from 'react'
import { useState, useEffect } from 'react';
import { IoMdArrowRoundBack } from "react-icons/io";
import { motion } from 'framer-motion'
import { useNavigate } from 'react-router-dom';
const Querythird = ({ setQuery, step, onGoback, handleContinue, handleCardClickFour, cardStepFour }) => {
    const [isInitialLoading, setIsInitialLoading] = useState(true);
    const navigate = useNavigate();
    useEffect(() => {
        const timer = setTimeout(() => {
            setIsInitialLoading(false);
            // fetchFilteredData()
        }, 4000);

        return () => clearTimeout(timer); 
    }, [step]);
    return (
        <div>
                    <motion.div
                        initial={{ opacity: 0, y: 50 }}
                        animate={{ opacity: 1, y: 0 }}
                        exit={{ opacity: 0, y: -50 }}
                        transition={{ duration: 0.5 }}
                    >
                        <div className="lg:pt-[20px] py-[10px] relative">
                            <div className="absolute top-[0] lg:top-10 lg:left-10 top-4 left-5 flex items-center cursor-pointer" onClick={() => navigate("/2")}>
                                <IoMdArrowRoundBack className="cursor-pointer"></IoMdArrowRoundBack>
                                {/* <span className="lg:text-[1rem] text-[0.6rem]">Back</span> */}
                            </div>
                            <section className="container mx-auto  min-h-[calc(100vh-11vh-10px-40px)] w-full flex flex-col items-center justify-between px-[1rem] sm:px-[2rem]">
                                {/* Header Section */}
                                <div className="text-center max-w-[80vw] mb-[24px]">
                                    <p className="text-richgreen-25 font-bold text-[1.1875rem] sm:text-[1.5rem] md:text-[2rem] lg:text-[2.5rem]">

                                        What are your top financial priorities?
                                    </p>
                                    {/* <div className="flex lg:flex-col items-center gap-[0.5rem] flex-row-reverse"> */}
                                        <p className="text-[1rem] sm:text-[1.25rem]">
                                            Choose as many as you’d like.
                                        </p>
                                        {/* <img src={star} className="w-[2.5rem] sm:w-[3.125rem] lg:w-[3.75rem] aspect-square" alt="Star Icon" /> */}
                                    {/* </div> */}
                                </div>
                                {/* Cards Section */}
                                <div className="w-full md:max-w-full md:w-auto grid grid-cols-1 sm:grid-cols-2 gap-[1rem] lg:gap-[2vw]">
                                    {cardStepFour.map((card, index) => (
                                        <div key={index} className="flex justify-center">
                                            <div
                                                onClick={() => handleCardClickFour(card.id, card)}
                                                className={`border-[1px] h-[75px] w-full sm:w-[100%] md:w-[100%] lg:w-[100%] xl:w-[30rem] h-[4rem] border-richgray-25 flex items-center gap-x-[0.5rem] rounded-xl p-[0.25rem] pl-[0.5rem] lg:pl-[1.25rem] cursor-pointer transition-all ease-in ${card.isClickedFour ? 'bg-richgreen-25 text-white' : 'bg-white text-black'} hover:shadow-lg`}
                                            >
                                                <img
                                                    src={
                                                        cardStepFour.id !== 8
                                                            ? card.isClickedFour
                                                                ? card.iconL
                                                                : card.iconD
                                                            : ""
                                                    }
                                                    className="w-[2.5rem] sm:w-[3.125rem] lg:w-[2rem] aspect-square"
                                                    alt={card.title}
                                                />
                                                <div>
                                                    <p className="text-[0.875rem] sm:text-[1rem] lg:text-[1.4rem]">{card.title}</p>

                                                </div>
                                            </div>
                                        </div>
                                    ))}

                                    {/* <div className="flex justify-center">
                                    <div className="border-[1px] w-full sm:w-[100%] md:w-[100%] lg:w-[100%] xl:w-[30rem] h-[4rem] border-richgray-25 flex items-center gap-x-[0.5rem] rounded-xl p-[0.25rem] pl-[0.5rem] lg:pl-[1.25rem] bg-white">
                                        <input
                                            type="text"
                                            placeholder="something else"
                                            className="w-full border-none outline-none  text-black placeholder-gray-400"
                                        />
                                    </div>
                                </div> */}
                                </div>


                                <div className="flex justify-center mt-[10px] w-full sticky bottom-[20px]">
                                    <button
                                        onClick={() => handleContinue("/4")}
                                        className="p-[10px] left-1/2  text-[1.125rem] sm:text-[1.25rem] lg:text-[1rem] w-full max-w-[14.95rem] h-[3.125rem] sm:h-[3.75rem] lg:h-[3.3rem] text-richgreen-5 font-inter bg-richgreen-800 rounded-xl shadow-lg"
                                    >
                                        Continue
                                    </button>
                                </div>




                            </section>
                        </div>

                    </motion.div>

        </div>
    )
}

export default Querythird
