import React, { useEffect, useState } from 'react'
import { baseUrl } from '../../utils/baseUrl';
import QuestionList from '../../Components/Dashbaoard/QuestionList';


const DeleteQuestion = () => {
    const [data, setData] = useState()
    const token = window.localStorage.getItem("token")


    const fetAllQueryData = async () => {
        try {
            const response = await fetch(`${baseUrl}/admin/get-all-query-list`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
            });
            const data = await response.json();
            if (!response.ok) {
                throw new Error(data?.message || "Data not found")
            }
            setData(data)
        } catch (error) {
            console.error({ error: error.message || "Internal server Error" })
        }
    }

    const handleDelete = async (id) => {
        console.log("delete id",id)
        try {
            const url = `${baseUrl}/search-page-query/delete-particular-query-records/${id}`
            const response = await fetch(url, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
            });
            const data = await response.json();
            if (!response.ok) {
                throw new Error(data?.message || "Data not found")
            }
            fetAllQueryData()
        } catch (error) {
            console.error({ error: error.message || "Internal server Error" })
        }
    };


    useEffect(() => {
        fetAllQueryData()
    }, [])
    return (
        <div>
            {data && <QuestionList data={data} onDelete={handleDelete} />}
        </div>
    )
}

export default DeleteQuestion
