import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom';
import { getAccessToken, getUser } from '../Calendy/Utils';

const ScheduleMeeting = () => {
    const location = useLocation();
    const [code, setCode] = useState(null);

    useEffect( () => {
        const getCalendyURL=async()=>{
            const searchParams = new URLSearchParams(location.search);
            const extractedCode = searchParams.get('code');
            console.log("extractedCode", extractedCode)
            setCode(extractedCode);
            if (extractedCode) {
                const token = await getAccessToken(extractedCode)
                console.log("token", token)
    
                if (token?.access_token) {
                    const User = await getUser(token.access_token)
                    const { scheduling_url } = User?.resource
                    window.location.href = scheduling_url;
                    console.log("scheduling_url",scheduling_url)
                    // scheduling_url && window.open(scheduling_url, '_blank');
                }
            }
        }
        getCalendyURL()
    }, [location])
    return (
        <div>
            <h2>{code && code}</h2>
        </div>
    )
}

export default ScheduleMeeting
