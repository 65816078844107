import { useEffect, useState } from "react";
import { baseUrl } from "../../../utils/baseUrl";
import axios from "axios";

const Sidebar = ({ img }) => {
    const [user, setUser] = useState()

    useEffect(() => {
        const fetchMyProfile = async () => {
            const token = window.localStorage.getItem("token")
            try {
                const response = await axios.get(`${baseUrl}/user/my-profile`, {
                    headers: {
                        "Authorization": `Bearer ${token}`
                    }
                });
                console.log("response", response.data)
                setUser(response?.data?.user);
            } catch (error) {
                console.error('Error fetching questions:', error);
            }
        }

        fetchMyProfile()
    }, [])

    const profileData = [
        { title: "Your Profile", content: "A Working Woman" },
        { title: "Reason To Visit Finright", content: "Seeking Assistance On Issues I Am Facing" },
        { title: "Your Style", content: "The DIY Guy - I Make My Own Financial Decisions" },
        { title: "Your Priorities", content: "Reduce My Tax Burden, Optimizing My Finances, Protection Against Any Mishaps" }
    ];

    return (
        <div className=" md:w-1/4 p-4 bg-white rounded-2xl shadow-md w-[300px] lg:block hidden h-auto border-[#D8D8D8] border-[2px]" style={{ boxShadow: "0px 12px 16px -4px #10182814" }} >
            <div className="flex flex-col items-center">
                <div className="border-[10px] border-[#702DFF] rounded-full w-28 h-28">
                    <img src={img} alt="profile" className="rounded-full w-24 h-24 border-[5px] border-[#FFFFFF] " />
                </div>
                <div className="grid place-items-center">
                    <h2 className="mt-4 text-[1.6rem] text-[#202020] font-semibold">
                        {(user?.firstName && user?.lastName) ? `${user.firstName} ${user.lastName}` : "John Doe"}
                    </h2>

                    <p className=" text-[#7E7E7E] font-semibold text-[1.2rem]">{user?.mobile || "N/A"}</p>
                    <p className="text-[#7E7E7E]">{user?.email || "N/A"}</p>
                </div>
            </div>
            <div className="mt-6 p-10">
                {profileData.map((item, index) => (
                    <div key={index} className="mt-4">
                        <h3 className="font-semibold text-[#202020] text-[1rem]">{item.title}</h3>
                        <p className="text-[#5F5F5F] text-[0.8rem]">{item.content}</p>
                    </div>
                ))}
            </div>

        </div>
    );
}

export default Sidebar;

