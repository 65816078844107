import React from 'react';
import { styled } from '@mui/material/styles';
import Navbar from '../Components/Navbar';
import { Outlet } from 'react-router-dom';
import { Box } from '@mui/material';
import AdminSidebar from './AdminSidebar';
import { Navigate } from 'react-router-dom';

const LayoutContainer = styled('div')`
  display: flex;
`;

const Content = styled('main')(({ theme }) => ({
  flexGrow: 1,
  padding: 24,
  [theme.breakpoints.down('sm')]: {
    marginLeft: 0,
  },
}));

const Layout = () => {

  const user = window.localStorage.getItem("admin_token") ? JSON.parse(window.localStorage.getItem("admin_token")) : null;
  console.log("user", user)
  if (!user || user.role !== "ADMIN") {
    return <Navigate to="/cms/panel/admin/login.dev" replace />;
  }

  return (
    <Box>
      <Navbar />
      <LayoutContainer>
        <AdminSidebar />
        <Content>
          <Outlet />
        </Content>
      </LayoutContainer>
    </Box>
  );
};

export default Layout;
