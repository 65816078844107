
// step 2

import support from '../../assests/icons/support.svg';
import support2 from "../../assests/icons/support2.svg"
import identify from "../../assests/icons/identify.svg"
import identify2 from "../../assests/icons/identify2.svg"


// step 3 icons
import investment from "../../assests/icons/investment.svg";
import investment2 from "../../assests/icons/investment2.svg" 
import interview from "../../assests/icons/interview.svg";
import interview2 from "../../assests/icons/interview2.svg";
import teach from "../../assests/icons/teach.svg";
import teach2 from "../../assests/icons/teach2.svg";
import maternity from "../../assests/icons/maternity.svg";
import maternity2 from "../../assests/icons/maternity2.svg";
import poteintial from "../../assests/icons/growth.svg";
import poteintial2 from "../../assests/icons/growth2.svg";
import services from "../../assests/icons/services.svg";
import services2 from "../../assests/icons/services2.svg"




// step 4 icons

// what are you top
import insurance from "../../assests/icons/insurance.svg";
import insurance2 from "../../assests/icons/insurance2.svg";
import liability from "../../assests/icons/liablity.svg";
import liability2 from "../../assests/icons/liablity2.svg";
import value from "../../assests/icons/value.svg";
import value2 from "../../assests/icons/value2.svg";
import peace from "../../assests/icons/meditation.svg";
import peace2 from "../../assests/icons/meditation2.svg";
import RockingChair from "../../assests/icons/chair.svg";
import RockingChair2 from "../../assests/icons/chair2.svg";
import NoTax from "../../assests/icons/notax.svg"
import NoTax2 from "../../assests/icons/notax2.svg"
import goal from "../../assests/icons/goal.svg"
import goal2 from "../../assests/icons/goal2.svg"


// step 5 icons

import card from "../../assests/icons/card.svg"
import card2 from "../../assests/icons/card2.svg"
import woman from "../../assests/icons/workingWoman.svg"
import woman2 from "../../assests/icons/workingWoman2.svg"
import embassy from "../../assests/icons/embassy.svg";
import embassy2 from "../../assests/icons/embassy2.svg"
import selfEmp from "../../assests/icons/freelancer.svg"
import selfEmp2 from "../../assests/icons/freelancer2.svg"
import NRI from "../../assests/icons/passport.svg"
import NRI2 from "../../assests/icons/passport2.svg"
import Student from "../../assests/icons/student.svg"
import Student2 from "../../assests/icons/student2.svg"
import office from "../../assests/icons/office.svg"
import office2 from "../../assests/icons/office2.svg"
import earner from "../../assests/icons/hand.svg"
import earner2 from "../../assests/icons/hand2.svg"
 


export const sideBarLink = [
    { 
      title: {label:"Category",value:"category"}, 
      labels: [
        { title: "Insurance", value: "Insurance" }, 
        { title: "Investments", value: "Investments" }, 
        { title: "Taxation", value: "Taxation" }, 
        { title: "Provident Fund", value: "Provident Fund" }, 
        { title: "Loans & EMIs", value: "Loans" }
      ] 
    },
    { 
      title: {label:"Goal",value:"goals"}, 
      labels: [
        { title: "Protection against mishap", value: "Protection Against Mishap" }, 
        { title: "Having peace of mind", value: "Having Peace of Mind" }, 
        { title: "Increase my Networth", value: "Increase my Networth" }, 
        { title: "Reduce debt burden", value: "Reduce debt burden" }, 
        { title: "Make progress towards Goals", value: "Make progress towards Goals" }, 
        { title: "Save more tax", value: "Save More Tax" }, 
        { title: "Retire early and comfortably", value: "Retire early and comfortably" }
      ] 
    },
    // { 
    //   title: {label:"Identity",value:"identity"}, 
    //   labels: [
    //     { title: "Senior Citizen (More than 60yo)", value: "Senior Citizen" }, 
    //     { title: "Young India (18-25yo)", value: "Young India" }, 
    //     { title: "Early Professional (25-25yo)", value: "Early Professional" }, 
    //     { title: "Seasoned Experts (50-60yo)", value: "Seasoned Experts" }, 
    //     { title: "Loan & EMI", value: "Loan & EMI" }
    //   ] 
    // },
    { 
      title: {label:"Profession",value:"profession"}, 
      labels: [
        { title: "Govt. PSU Sector", value: "Govt. PSU Sector" }, 
        { title: "Pvt. Sector", value: "Pvt. Sector" }, 
        { title: "Self Employed", value: "Self Employed" }, 
        { title: "Working Woman", value: "Working Woman" }, 
        { title: "Student/Unemployed", value: "Student/Unemployed" }, 
        { title: "First Time employee", value: "First Time employee" }, 
        { title: "Single Earner in family", value: "Single Earner in family" }, 
        { title: "NRI/ Recently immigrated Indian", value: "NRI/ Recently immigrated Indian" }
      ] 
    },
    // { 
    //   title: {label:"Financial Status",value:"financialStatus"}, 
    //   labels: [
    //     { title: "Top 1% Earning (> 50lpa)", value: "Top 1% Earning" }, 
    //     { title: "Middle Income (20-50 lpa)", value: "Middle Income" }, 
    //     { title: "Ambitious (7-20 lpa)", value: "Ambitious" }
    //   ] 
    // },
    { 
        title: {label:"Experience",value:"experience"}, 
        labels: [
          { title: "Senior Citizen", value: "Senior Citizen" }, 
          { title: "Young India", value: "Young India" }, 
          { title: "Early Professional", value: "Early Professional" },
          { title: "Executives", value: "Executives" },
          { title: "Seasoned Experts", value: "Seasoned Experts" },
        ] 
      }
  ];
  
 

export const step2 = [
    { id: 1, text: 'I have a Financial Query', iconD: support, iconL: support2, isClicked: false },
    { id: 2, text: 'View queries for my profile', iconD: identify, iconL: identify2, isClicked: false },
    // Add more cards as needed
];

export const step3 = [
    { id: 1, title: 'The DIY guy', description: 'I manage my family’s finances', iconD: investment, iconL: investment2, iSClicked: false },
    { id: 2, title: 'The Advise Seeker', description: 'I take advise from friends and Family', iconD: interview, iconL: interview2, iSClicked: false },
    { id: 3, title: 'The Consultant', description: 'Others seek advise from me', iconD: teach, iconL: teach2, iSClicked: false },
    { id: 4, title: 'The Responsible one', description: 'I manage my family’s finances', iconD: maternity, iconL: maternity2, iSClicked: false },
    { id: 5, title: 'The high potential one', description: 'I know how but need a coach', iconD: poteintial, iconL:poteintial2, iSClicked: false },
    { id: 6, title: 'The Outsourcing Champ', description: 'I delegate to achieve my goals',iconD: services, iconL:services2, iSClicked: false },
];


export const step4 = [
    { id: 1, title: 'Protection against mishap', iconD: insurance, iconL: insurance2, isClickedFour: false },
    { id: 2, title: 'Reduce debt burden', iconD: liability, iconL: liability2, isClickedFour: false },
    { id: 3, title: 'Increase my net worth', iconD: value, iconL: value2, isClickedFour: false },
    { id: 4, title: 'Having peace of mind', iconD: peace, iconL: peace2, isClickedFour: false },
    { id: 5, title: 'Retire early and comfortably', iconD: RockingChair, iconL: RockingChair2, isClickedFour: false },
    { id: 6, title: 'Save more tax', iconD: NoTax, iconL: NoTax2, isClickedFour: false },
    { id: 7, title: 'Make progress toward goals', iconD: goal, iconL: goal2, isClickedFour: false },
];


export const step5 = [
    { id: 3, title: 'First Time Employee', iconD: card, iconL: card2, isClickedFive: false },
    { id: 1, title: 'A Working Woman', iconD: woman, iconL: woman2, isClickedFive: false },
    { id: 5, title: 'Govt. Sector Employee', iconD: embassy, iconL: embassy2, isClickedFive: false },
    { id: 7, title: 'Self employed', iconD: selfEmp, iconL: selfEmp2, isClickedFive: false },
    { id: 2, title: 'NRI/ Recently immigrated Indian', iconD: NRI, iconL: NRI2, isClickedFive: false },
    { id: 4, title: 'Student/ Unemployed', iconD: Student, iconL: Student2, isClickedFive: false },
    { id: 6, title: 'Pvt. Sector Employee', iconD: office, iconL: office2, isClickedFive: false },
    { id: 8, title: 'Single earner in family', iconD: earner, iconL: earner2, isClickedFive: false },
];


export const cardData = [
    {
        id: 1,
        title: 'Are There Any Red Flags in My Health Insurance?',
        content: 'Employer Insurance does not support critical illness and caps the...',
        info: 'Do you know',
        know_More_heading: 'High Out of pocket medical expenses pushed 5.5 Cr Indians into poverty',
        know_More_Subheading: 'Only 44% of people have health coverage in India.',
    },
    {
        id: 2,
        title: 'Can I Withdraw my Provident Fund?',
        content: '30% of total PF claims are rejected every year by EPFO without any clear...',
        info: 'Do you know',
        know_More_heading: 'Many employees face challenges in accessing their Provident Fund',
        know_More_Subheading: 'About 50% of EPF accounts remain inactive for years.',
    },
    {
        id: 3,
        title: 'Which Govt. Schemes are Good Investment options?',
        content: 'Investment schemes like Sovereign Gold Bonds and Sukanya Samridhi are better than...',
        info: 'Do you know',
        know_More_heading: 'Sovereign Gold Bonds offer interest in addition to price appreciation',
        know_More_Subheading: 'Sukanya Samriddhi Yojana offers a tax-free return of around 7.6%.',
    },
    {
        id: 4,
        title: 'Is Your Health Insurance Covering Everything?',
        content: 'Many policies exclude critical illnesses or have caps on claims...',
        info: 'Do you know',
        know_More_heading: '70% of medical expenses are paid out of pocket in India',
        know_More_Subheading: 'Only 10% of health insurance policies cover critical illnesses.',
    },
    {
        id: 5,
        title: 'Can I Withdraw my Provident Fund Early?',
        content: 'Partial withdrawals from PF accounts are allowed in certain conditions...',
        info: 'Do you know',
        know_More_heading: 'Only 43% of working population contributes to Provident Fund regularly',
        know_More_Subheading: 'About 3.67% of salary goes towards your EPF account.',
    },
    {
        id: 6,
        title: 'Which Govt. Schemes are Best for Long-term Savings?',
        content: 'The National Pension Scheme (NPS) and Public Provident Fund (PPF) are good...',
        info: 'Do you know',
        know_More_heading: 'PPF offers tax-free interest and safe long-term returns',
        know_More_Subheading: 'NPS gives you additional tax deductions under section 80CCD(1B).',
    },
];


export const queries = [
    {
        id: 1,
        title: "Are There Any Red Flags in My Health Insurance?",
        sectionTitle: "Why You Shouldn’t Rely Solely On Employer-Provided Health Insurance?",
        subsections: [
            {
                title: "Understanding The Risk",
                content: [
                    "You Might Assume Your Employer’s Health Insurance Fully Covers You, But This Can Leave You Vulnerable. Employer-Provided Insurance Often Offers Only Basic Coverage, Which May Not Be Sufficient During a Major Medical Emergency. Additionally, Changing Jobs Or Retiring Could Leave You Without Coverage During Critical Times."
                ]
            },
            {
                title: "Key Considerations To Ensure A Protected Medical Future",
                content: [
                    {
                        subheading: "Limited Coverage:",
                        text: "Employer Plans In India Typically Offer Only ₹2 Lakh To ₹5 Lakh, Which May Be Inadequate For Significant Health Events."
                    },
                    {
                        subheading: "Portability Issues:",
                        text: "Approximately 40% Of Employees Experience Gaps In Coverage When Switching Jobs, According To Policybazaar, Exposing Them To Financial Risks."
                    },
                    {
                        subheading: "Rising Medical Costs:",
                        text: "Healthcare Inflation Is Growing 8-15% Annually In India, Outpacing General Inflation."
                    }
                ]
            },
            {
                title: "Action Steps You Need To Take",
                content: [
                    "1. Assess Your Coverage: Compare Your Employer’s Plan Against Your Potential Medical Expenses. If The Coverage Is Low, Consider Adding Private Insurance Or A Super Top-Up Plan.",
                    "2. Plan For Job Changes: If Your Employment Is Uncertain, Having Private Insurance Ensures Continuous Coverage.",
                    "3. Fill Critical Gaps: Employer Plans Often Lack Critical Illness Coverage. A Private Policy Can Cover Conditions Like Cancer Or Heart Disease.",
                    "4. Ensure Family Coverage: Make Sure Your Dependents Are Adequately Covered. If Not, A Private Family Floater Plan May Be Necessary.",
                    "Prepare For Retirement: Employer Health Insurance Usually Ends At Retirement. A Private Policy Ensures Coverage During Your Senior Years When Healthcare Needs Typically Increase."
                ]
            },
            {
                sectionTitle: "Learn From Mrs. Rao’s Lesson: How Employer Health Insurance Can Fall Short When You Need It Most",
                content: [
                    "Mrs. Rao, a 55-Year-Old IT Professional, Discovered Her Employer’s Health Insurance Wasn’t Enough When Her Husband Needed Surgery Costing ₹8 Lakh. Her ₹3 Lakh Coverage Was Quickly Maxed Out. After Consulting With Experts, She Purchased A Private Health Insurance Plan With A ₹10 Lakh Sum Insured And A Critical Illness Rider, Ensuring Her Family Was Financially Protected."
                ]
            },
            {
                sectionTitle: "Final Thoughts",
                content: [
                    "Don’t Wait Until It’s Too Late. Take Action Now To Evaluate Your Health Coverage. Book A One-On-One Consultation With Us To Ensure You’re Fully Protected Against Life’s Unpredictable Health Challenges."
                ]
            }
        ]
    },
    {
        id: 2,
        title: "Can I Withdraw my Provident Fund?",
        sectionTitle: "How To Navigate PF Withdrawals Without Facing Rejection?",
        subsections: [
            {
                title: "Why PF Claims Are Often Rejected",
                content: [
                    "About 30% Of Provident Fund Claims Are Rejected Every Year Due To Issues Like Incomplete Paperwork Or Mismatch Of Details. Ensure You Have Your KYC Updated And All Necessary Documents In Place Before Applying."
                ]
            },
            {
                title: "Steps For A Successful PF Withdrawal",
                content: [
                    "1. Ensure Your KYC Is Completed: This Is The Most Common Reason For Rejections.",
                    "2. Match Details With Employer Records: Ensure Your Name And Date Of Birth Match With EPFO Records.",
                    "3. Check Withdrawal Eligibility: Withdrawals Are Only Allowed In Specific Conditions, Such As Medical Emergencies Or Job Loss."
                ]
            },
            {
                sectionTitle: "Learn From Real-Life Scenarios",
                content: [
                    "Many Employees Have Faced The Hassle Of PF Rejection Due To Minor Mistakes. Mr. Singh, A 45-Year-Old Teacher, Had His PF Claim Rejected Twice Due To Mismatch In His KYC Details. After Correcting His Information, He Successfully Withdrew His PF Within Two Weeks."
                ]
            },
            {
                sectionTitle: "Final Tips",
                content: [
                    "Always Double-Check Your Details Before Filing A PF Claim. A Smooth Process Can Ensure You Get Your Funds On Time Without Unnecessary Delays."
                ]
            }
        ]
    },
    {
        id: 3,
        title: "Which Govt. Schemes are Good Investment options?",
        sectionTitle: "Understanding Govt. Schemes For Secure Investments",
        subsections: [
            {
                title: "Popular Govt. Schemes You Should Know About",
                content: [
                    "1. Sovereign Gold Bonds: These Bonds Offer Interest Along With Capital Appreciation Linked To The Price Of Gold, Making Them A Better Alternative To Buying Physical Gold.",
                    "2. Sukanya Samriddhi Yojana: A Great Scheme For Parents Of A Girl Child, Offering Tax-Free Returns And A Competitive Interest Rate."
                ]
            },
            {
                title: "Benefits Of Investing In Govt. Schemes",
                content: [
                    "1. Safe And Secure: These Schemes Are Backed By The Government, Making Them Extremely Safe For Conservative Investors.",
                    "2. Tax Benefits: Most Schemes, Such As PPF And Sukanya Samriddhi Yojana, Offer Tax-Free Returns."
                ]
            },
            {
                sectionTitle: "Real-Life Success Stories",
                content: [
                    "Ms. Patel Invested ₹1 Lakh In Sovereign Gold Bonds And Received Both Capital Appreciation And Interest Over The Years. This Turned Out To Be A Lucrative Investment Compared To Buying Physical Gold, As She Avoided Storage Hassles And Received Regular Interest Payments."
                ]
            },
            {
                sectionTitle: "Final Thoughts",
                content: [
                    "Investing In Govt. Schemes Is A Low-Risk Way To Build Wealth Over Time. Assess Your Financial Goals And Consider These Schemes For Long-Term Growth."
                ]
            }
        ]
    },
    {
        id: 4,
        title: "Is Your Health Insurance Covering Everything?",
        sectionTitle: "Common Gaps In Health Insurance You Might Not Know About",
        subsections: [
            {
                title: "Critical Illnesses Not Covered",
                content: [
                    "Many Health Insurance Policies Exclude Critical Illnesses Like Cancer, Stroke, Or Heart Disease. Ensure You Check The Policy Fine Print For What Is Included."
                ]
            },
            {
                title: "Claim Caps",
                content: [
                    "Most Policies Have Claim Caps That Limit The Maximum Amount You Can Claim For Certain Treatments. This Could Leave You With Out-Of-Pocket Expenses During Major Surgeries Or Prolonged Hospital Stays."
                ]
            },
            {
                sectionTitle: "Real Stories From Policyholders",
                content: [
                    "Mr. Sharma Realized His Health Insurance Didn’t Cover Critical Illnesses After He Was Diagnosed With Cancer. He Had To Pay ₹5 Lakh Out Of Pocket Despite Having Insurance, Prompting Him To Buy An Additional Critical Illness Policy."
                ]
            },
            {
                sectionTitle: "Steps To Take",
                content: [
                    "Review Your Policy And Consider Adding Critical Illness Coverage. Talk To An Insurance Advisor About Super Top-Up Plans To Cover Additional Medical Expenses."
                ]
            }
        ]
    },
    {
        id: 5,
        title: "Can I Withdraw my Provident Fund Early?",
        sectionTitle: "Understanding The Rules For Early PF Withdrawals",
        subsections: [
            {
                title: "Conditions For Early Withdrawals",
                content: [
                    "EPFO Allows Partial Withdrawals From Your PF Account Under Certain Conditions Like Medical Emergencies, Education Expenses, Or Purchasing A Home. Know The Eligibility Criteria Before Applying."
                ]
            },
            {
                title: "Tax Implications",
                content: [
                    "Withdrawals Before Completing Five Years Of Service Are Taxable. Ensure You Understand The Tax Impact Before Making Early Withdrawals."
                ]
            },
            {
                sectionTitle: "Avoid Common Mistakes",
                content: [
                    "Ensure You Meet All Conditions And Have Proper Documentation To Avoid Rejection. Many Employees Face Issues Due To Incomplete Applications."
                ]
            }
        ]
    },
    {
        id: 6,
        title: "Which Govt. Schemes are Best for Long-term Savings?",
        sectionTitle: "Maximizing Long-Term Savings Through Govt. Schemes",
        subsections: [
            {
                title: "National Pension Scheme (NPS)",
                content: [
                    "The National Pension Scheme Is An Excellent Long-Term Savings Option, Providing Market-Linked Returns With Tax Benefits. It Also Allows Additional Deductions Under Section 80CCD(1B)."
                ]
            },
            {
                title: "Public Provident Fund (PPF)",
                content: [
                    "PPF Offers Guaranteed, Tax-Free Interest And Is One Of The Safest Long-Term Savings Options In India. It’s Ideal For Conservative Investors Seeking Stability."
                ]
            },
            {
                sectionTitle: "Success Stories",
                content: [
                    "Ms. Rao Consistently Contributed To Her PPF Account For 15 Years, Which Helped Her Accumulate A Large Corpus Without Any Tax Liabilities. This Became A Key Part Of Her Retirement Plan."
                ]
            }
        ]
    }
];

