import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";

export const Protected = ({ children }) => {
    const navigate = useNavigate();
    // const { token } = useSelector((state) => state.auth);
    const token=localStorage.getItem("token") ||null

    useEffect(() => {
        if (token === null) {
            console.log("token",token)
            navigate("/user/login");
        }
    }, [token, navigate]);

    if (token !== null) { 
        return children;
    }

    return null;
};
