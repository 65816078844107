import React from "react";
import { useState } from "react";
import { AuthEndPoints } from "../apis";
// import { useSelector } from "react-redux";
import { apiConnector } from "../apiConnection";
import { setToken } from "../../Slices/authSlice";
import { setUser } from "../../Slices/profileSlice";
import { toast } from "react-hot-toast"
const { VERIFY_API, SIGNUP_API, GETSTART_API, LOGIN_API } = AuthEndPoints;


export const getStart_AC = async(email) => {
        try {
            const response = await apiConnector("POST", GETSTART_API, { email })
            console.log("GetStart API response",response, response?.data?.user?.token)
            if (response?.status !== 201 && response?.status !== 200) {
                throw new Error(response.data.message)
            }
            return response;
        } catch (error) {
            console.error("Couldn't Registered", error.message);
            return false
        }
}


export const sendOtp = (data, token) => {
    return async (dispatch) => {
        // const toastId = toast.loading("Loading...");

        try {
            const response = await apiConnector("POST", SIGNUP_API, data, {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${token}`,
            });

            toast.success(response.message);
            if (!response.success) {
                console.log("response.message2",response.data.message)
                throw new Error(response.message);
            }
        } catch (error) {
            console.log("response.message",error)
            toast.error(error.message);
            toast.error("error in sending OTP");
        } finally {
            // toast.dismiss(toastId);
        }
        // dispatch(setLoading(false));
    }
};




export const VerifyOtp_A = (data) => {
    return async (dispatch) => {

        try {
            const response = await fetch(VERIFY_API, {
                method: "PATCH",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(data),
            });

            const responseData = await response.json();

            // Check if the API response indicates success
            if (response.ok) {
                // Success case: dispatch actions and store data
                dispatch(setUser(responseData?.data));
                dispatch(setToken(responseData?.data?.user?.token));

                // Store token and user information in localStorage
                localStorage.setItem("token", responseData?.data?.user?.token);
                localStorage.setItem("user", JSON.stringify(responseData?.data?.user));

                // Show success toast
                toast.success(responseData?.message || "OTP Verified Successfully");
                console.log("Saved token and user data to localStorage");
                 return 
            
            } else {
                // Error case: handle errors if response is not ok
                const errorMessage = responseData?.message || "Failed to verify OTP";
                toast.error(errorMessage);
                throw new Error(errorMessage);
            }

        } catch (error) {
            // Catch block for handling any unexpected errors
            console.error("Couldn't verify OTP", error.message);
            toast.error("Failed to verify OTP. Please try again.");
        }

 
    };
};


export const login_A = (phoneNumber) => {
    return async (dispatch) => {
        console.log("number", phoneNumber)
        try {
            const response = await apiConnector("POST", LOGIN_API, { mobile: phoneNumber }, {
                "Content-Type": "application/json",
            });

            console.log("response", response)
            if (response?.status !== 200) {
                throw new Error(response.message || "Failed to send OTP");
            }
            console.log("OTP Sent Successfully", response.data);
            toast.success("OTP Sent Successfully");
            return response
        } catch (error) {
            console.error("Error during OTP login", error.message);
        }
    }
};
