import React from 'react'
import ExpertAssistance from '../Components/Home/ExpertAssistance'
import Navbar from '../Components/Navbar'

const TakeExpert = () => {
  return (
    <div>
        <Navbar/>
        <ExpertAssistance/>
    </div>
  )
}

export default TakeExpert
