import { createSlice } from '@reduxjs/toolkit';

const isValid=localStorage.getItem("token") && String(localStorage.getItem("isDetailFilled")) === "true"
const initialState = {
  cart: [],
  otherRelevant:sessionStorage.getItem('otherQueries')?JSON.parse(sessionStorage.getItem('otherQueries')):[],
  assistanceId:null,
  isLoggedIn :isValid ? true : false,
};

const querySlice = createSlice({
  name: 'query',
  initialState,
  reducers: {
    addToCart: (state, action) => {
      const { _id } = action.payload;
      const existsInCart = state.cart.some(item => item._id === _id);

      if (!existsInCart) {
        state.cart.push(action.payload);
        state.otherRelevant = state.otherRelevant.filter(item => item._id !== _id);
      } else {
        state.cart = state.cart.filter(item => item._id !== _id);
      }
    }, 
    addToCartFromOtherRelavant: (state, action) => {
      const { _id} = action.payload;
      const existsInCart = state.cart.some(item => item._id === _id);

      if (!existsInCart) {
        state.cart.unshift(action.payload);
        state.otherRelevant = state.otherRelevant.filter(item => item._id !== _id);
      } else {
        state.cart = state.cart.filter(item => item._id !== _id);
      }
    },
    removeFromCart: (state, action) => {
      state.cart = state.cart.filter(item => item._id !== action.payload._id);
    },
    emptyCart: (state) => {
      state.cart = [];
    },
    otherRelevantQueries: (state, action) => {
      const otherRelevant = action.payload;
      state.otherRelevant = otherRelevant.filter(query => !state.cart.some(cartItem => cartItem._id === query._id)).slice(0, 10);
    },
    refreshOtherRelevant: (state) => {
      state.otherRelevant = state.otherRelevant.filter(query => !state.cart.some(cartItem => cartItem._id === query._id)).slice(0, 10);
    },
    setLoggedIn: (state, action) => {
      state.isLoggedIn = action.payload; 
    },
    addAssitanceId:(state,action)=>{
       state.assistanceId=action.payload
    },
    resetState: (state) => {
      return initialState;
    },
     updateCartFromSession: (state, action) => {
      state.cart = action.payload; 
    }
  },
});

export const { addToCart, removeFromCart, emptyCart, setLoggedIn,otherRelevantQueries,refreshOtherRelevant,addAssitanceId,resetState,updateCartFromSession,addToCartFromOtherRelavant } = querySlice.actions;

export default querySlice.reducer;





// import { createSlice } from '@reduxjs/toolkit';

// const getLocalStorageItem = (key) => {
//   try {
//     return localStorage.getItem(key);
//   } catch (error) {
//     console.error(`Error accessing localStorage key "${key}":`, error);
//     return null;
//   }
// };

// const getSessionStorageItem = (key) => {
//   try {
//     const item = sessionStorage.getItem(key);
//     return item ? JSON.parse(item) : [];
//   } catch (error) {
//     console.error(`Error parsing sessionStorage key "${key}":`, error);
//     return [];
//   }
// };

// const getInitialState = () => {
//   const isValid = getLocalStorageItem("token") && String(getLocalStorageItem("isDetailFilled")) === "true";
//   return {
//     cart: [],
//     otherRelevant: getSessionStorageItem('otherQueries'),
//     assistanceId: null,
//     isLoggedIn: isValid ? true : false,
//   };
// };

// const initialState = getInitialState();

// const querySlice = createSlice({
//   name: 'query',
//   initialState,
//   reducers: {
//     addToCart: (state, action) => {
//       const { _id } = action.payload;
//       const existsInCart = state.cart.some(item => item._id === _id);

//       if (!existsInCart) {
//         state.cart.push(action.payload);
//         state.otherRelevant = state.otherRelevant.filter(item => item._id !== _id);
//       } else {
//         state.cart = state.cart.filter(item => item._id !== _id);
//       }
//     },
//     addToCartFromOtherRelavant: (state, action) => {
//       const { _id } = action.payload;
//       const existsInCart = state.cart.some(item => item._id === _id);

//       if (!existsInCart) {
//         state.cart.unshift(action.payload);
//         state.otherRelevant = state.otherRelevant.filter(item => item._id !== _id);
//       } else {
//         state.cart = state.cart.filter(item => item._id !== _id);
//       }
//     },
//     removeFromCart: (state, action) => {
//       state.cart = state.cart.filter(item => item._id !== action.payload._id);
//     },
//     emptyCart: (state) => {
//       state.cart = [];
//     },
//     otherRelevantQueries: (state, action) => {
//       const otherRelevant = action.payload;
//       state.otherRelevant = otherRelevant
//         .filter(query => !state.cart.some(cartItem => cartItem._id === query._id))
//         .slice(0, 10); // Limit to 10 items for performance
//     },
//     refreshOtherRelevant: (state) => {
//       state.otherRelevant = state.otherRelevant
//         .filter(query => !state.cart.some(cartItem => cartItem._id === query._id))
//         .slice(0, 10); // Limit to 10 items for performance
//     },
//     setLoggedIn: (state, action) => {
//       state.isLoggedIn = action.payload; 
//     },
//     addAssitanceId: (state, action) => {
//       state.assistanceId = action.payload;
//     },
//     resetState: (state) => {
//       return getInitialState(); // Reset state by re-initializing
//     },
//     updateCartFromSession: (state, action) => {
//       state.cart = action.payload;
//     },
//   },
// });

// export const { 
//   addToCart, 
//   removeFromCart, 
//   emptyCart, 
//   setLoggedIn, 
//   otherRelevantQueries, 
//   refreshOtherRelevant, 
//   addAssitanceId, 
//   resetState, 
//   updateCartFromSession, 
//   addToCartFromOtherRelavant 
// } = querySlice.actions;

// export default querySlice.reducer;
