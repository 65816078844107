
import { combineReducers } from "@reduxjs/toolkit";

import authReducer from "../Slices/authSlice"
import askQueryReducer from "../Slices/askQuerySlice"
import selectFilterReducer from "../Slices/filterSlice"
import modifyFilterReducer from "../Slices/modifyFiltersSlice"
import personalisedFilterReducer from "../Slices/personaliseFilterSlice"
// import prevPathReducers from "../Slices/NavigationSlice"

const rootReducer = combineReducers({
    auth: authReducer,
    askQuery:askQueryReducer,
    selectFilters:selectFilterReducer,
    selectModifyFilters:modifyFilterReducer,
    selectPersonalisedFilters:personalisedFilterReducer
    // prevPath:prevPathReducers
    // profile: profileReducer,
})
export default rootReducer