import React, { useState } from "react";
import { Modal, TextField, Button, IconButton } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';

const FeedbackForm = ({ open, handleClose, handleSubmit, feedbackId }) => {
    const [feedback, setFeedback] = useState("");

    const handleFeedbackChange = (e) => {
        setFeedback(e.target.value);
    };

    const onSubmit = () => {
        handleSubmit(feedbackId, feedback, () => {
            setFeedback("");
            handleClose();    
        });
    }

    return (
        <Modal open={open} onClose={handleClose}>
            <div style={{ padding: "20px", backgroundColor: "white", margin: "20vh auto", width: "400px", borderRadius: "10px", position: 'relative' }}>
                {/* Close Button */}
                <IconButton 
                    onClick={handleClose} 
                    style={{ position: 'absolute', top: 10, right: 10 }}
                >
                    <CloseIcon />
                </IconButton>
                
                <h3>Leave Feedback</h3>
                <TextField
                    label="Your Feedback"
                    multiline
                    rows={4}
                    fullWidth
                    variant="outlined"
                    value={feedback}
                    onChange={handleFeedbackChange}
                    style={{ marginBottom: "20px" }}
                />
                <Button variant="contained" color="primary" onClick={onSubmit}>
                    Submit Feedback
                </Button>
            </div>
        </Modal>
    );
};

export default FeedbackForm;
