import React, { useEffect, useState } from "react";
import logo from "../../assests/logo.png";
import { ImFilter } from "react-icons/im";
import { MdOutlineKeyboardArrowDown } from "react-icons/md";
import { CiSearch } from "react-icons/ci";
import { PiDownloadLight, PiUploadLight } from "react-icons/pi";
import {
    Modal,
    ListItem,
    Divider,
    List,
    ListItemButton,
    ListItemIcon,
    Drawer,
    ListItemText, MenuItem,
    Select, TextField
} from "@mui/material";
import Box from "@mui/material/Box";
import LogoutIcon from '@mui/icons-material/Logout';
import { Link } from "react-router-dom";
import axios from "axios";
import { baseUrl } from "../../utils/baseUrl";
import * as XLSX from 'xlsx';


const AdminDashboard = () => {
    const [open, setOpen] = useState(false);
    const [expandedIndex, setExpandedIndex] = useState(null);
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [selectedModalData, setSelectedModalData] = useState(null);
    const [tableData, setTableData] = useState([])
    const [searchQuery, setSearchQuery] = useState("");
    const [selectedDate, setSelectedDate] = useState("");
    const [dateValue, setDateValue] = useState(null);

    const toggleDrawer = (openState) => () => {
        setDrawerOpen(openState);
    };

    const handleDateChange = (e) => {
        setDateValue(e.target.value);
        setSelectedDate(e.target.value);
    };

    useEffect(() => {
        const fetchAllAssistanceRequest = async () => {
            try {
                const response = await axios.get(`${baseUrl}/admin/all-assistance-request`);
                console.log("response", response.data)
                setTableData(response?.data?.assistanceLogs);
            } catch (error) {
                console.error('Error fetching questions:', error);
            }
        }

        fetchAllAssistanceRequest()

    }, [])



    const handleOpenModel = (data) => {
        setOpen(true)
        setSelectedModalData(data)
    }
    const handleClose = () => {
        setOpen(false)
        setSelectedModalData(null);
    }


    const toggleShowAll = (index) => {
        setExpandedIndex((prevIndex) => (prevIndex === index ? null : index));
    };

    const filteredData = tableData.filter((data) => {
        const fullName = `${data?.userId?.firstName} ${data?.userId?.lastName}`.toLowerCase();
        const mobileOrEmail = (data.userId?.mobile || data.userId?.email || "").toLowerCase();
        const searchMatch = fullName.includes(searchQuery.toLowerCase()) ||
            mobileOrEmail.includes(searchQuery.toLowerCase());

        const dateMatch = selectedDate
            ? new Date(data.createdAt).toLocaleDateString() === new Date(selectedDate).toLocaleDateString()
            : true;

        return searchMatch && dateMatch;
    });


    const handleExport = () => {
        const workbook = XLSX.utils.book_new();
        const worksheet = XLSX.utils.json_to_sheet(filteredData.map(data => ({
            'Client Name': `${data.userId?.firstName} ${data.userId?.lastName}`,
            'Mobile/Email': data.userId?.mobile || data.userId?.email,
            'Date': new Date(data.createdAt).toLocaleDateString(),
            'Queries Raised': data.queries.map(query => query.question).join(', '),
            'Assistance': data.status,
        })));

        XLSX.utils.book_append_sheet(workbook, worksheet, "Assistance Requests");

        XLSX.writeFile(workbook, "AssistanceRequests.xlsx");
    };
    const DrawerList = (
        <Box sx={{ width: 250, display: 'flex', flexDirection: 'column', height: '100vh' }} role="presentation" onClick={toggleDrawer(false)}>
            <Link to="/">
                <img src={logo} alt="logo" className="m-4 w-[150px]" />
            </Link>

            <Divider />

            {/* Logout Link at the Bottom */}
            <Box sx={{ mt: 'auto', mb: 2 }}>
                <ListItem disablePadding>
                    <ListItemButton component={Link} to="/logout">
                        <ListItemIcon><LogoutIcon /></ListItemIcon>
                        <ListItemText primary="Logout" />
                    </ListItemButton>
                </ListItem>
            </Box>
        </Box>
    );
    return (
        <section className="bg-[#EBF3FF] w-full   relative">
            <Link to={"/"}><img src={logo} className="m-5" alt="logo" /></Link>
            {/* Main container */}

            <div className="w-[100%] flex items-center h-[5rem]  mx-auto mt-14 justify-between " >
                <div className="w-[100%] border-2 border-[#D5D5D5] rounded-2xl mx-auto  " style={{ boxShadow: "0px 4px 4px 0px #00000040" }}>
                    {/* Filters container */}
                    <div className="bg-[#F9F9FB] rounded-2xl h-[5rem] flex flex-wrap items-center justify-between   md:gap-y-0">
                        {/* Filter Icon */}
                        <div className="grid place-content-center w-[10rem]  ">
                            <ImFilter className="w-[25px] h-[25px] mx-4" />
                        </div>

                        <div className="flex items-center h-full border-r border-[#979797] px-3">
                            <p className="font-bold">Filter By Date</p>

                            <Select
                                value={selectedDate}
                                onChange={(e) => {
                                    const value = e.target.value;
                                    setSelectedDate(value);
                                    if (value === "today") {
                                        const today = new Date().toISOString().split("T")[0];
                                        setDateValue(today); // Set dateValue to today's date
                                    } else if (value === "yesterday") {
                                        const yesterday = new Date(new Date().setDate(new Date().getDate() - 1)).toISOString().split("T")[0];
                                        setDateValue(yesterday); // Set dateValue to yesterday's date
                                    } else {
                                        setDateValue(""); // Clear custom date
                                    }
                                }}
                                displayEmpty
                                className="ml-2"
                                size="small"
                            >
                                <MenuItem value="">All Dates</MenuItem>
                                <MenuItem value="today">Today</MenuItem>
                                <MenuItem value="yesterday">Yesterday</MenuItem>
                                <MenuItem value="custom">Custom Date</MenuItem>
                            </Select>

                            {/* Custom HTML Date Picker for selecting a custom date */}
                            {selectedDate === "custom" && (
                                <input
                                    type="date"
                                    value={dateValue}
                                    onChange={handleDateChange}
                                    className="ml-2"
                                />
                            )}
                             <MdOutlineKeyboardArrowDown className="ml-2 font-bold" size={20} />
                        </div>
                        {/* Search Bar */}
                        <div className="flex items-center w-full md:w-[40%] border-r lg:w-[30%] bg-white rounded-3xl px-5 border-[#D5D5D5]">
                            <CiSearch className="mr-3 font-bold" size={20} />
                            <input
                                type="text"
                                placeholder="Search by name, email, or mobile"
                                value={searchQuery}
                                onChange={(e) => setSearchQuery(e.target.value)}
                                className="w-full py-2 focus:outline-none"
                            />
                        </div>

                        {/* Export Button */}
                        <div className="flex items-center gap-4 border-l h-full border-[#979797] px-3">
                            <p className="text-[red] font-bold cursor-pointer" onClick={handleExport}>Export</p>
                            <PiDownloadLight size={20} className="cursor-pointer" onClick={handleExport} />
                        </div>
                    </div>


                </div>
            </div>
            <div className="w-[100%] border-2 border-[#D5D5D5] rounded-2xl mx-auto mt-14" style={{ boxShadow: "0px 4px 4px 0px #00000040" }}>
                <section className="bg-[#F9F9FB] rounded-2xl flex flex-col gap-4 p-10">



                    {/* Table Data */}
                    <div className="overflow-x-auto">
                        <table className="min-w-full table-auto border-collapse border-spacing-0">
                            <thead>
                                <tr className="bg-gray-100 text-left text-[#797D8C] text-[14px] font-semibold">
                                    <th className="px-4 py-3 w-[15%]">Client Name</th>
                                    <th className="px-4 py-3 w-[15%]">Mobile/Email</th>
                                    <th className="px-4 py-3 w-[15%]">Date</th>
                                    <th className="px-4 py-3 w-[20%]">Queries Raised</th>
                                    <th className="px-4 py-3 w-[15%]">Assistance</th>
                                    <th className="px-4 py-3 w-[10%]">Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {filteredData &&
                                    filteredData.map((data, index) => (
                                        <tr key={index} className="border-b-[1px] border-[#D5D5D5] text-[#797D8C] text-[14px]">
                                            {/* Client Name */}
                                            <td className="px-4 py-3">
                                                {data?.userId?.firstName && data?.userId?.lastName
                                                    ? `${data?.userId?.firstName} ${data?.userId?.lastName}`
                                                    : "N/A"}
                                            </td>

                                            {/* Mobile No */}
                                            <td className="px-4 py-3">
                                                {data.userId?.mobile || data.userId?.email}
                                            </td>

                                            {/* Date */}
                                            <td className="px-4 py-3">
                                                {new Date(data.createdAt).toLocaleDateString()}
                                            </td>

                                            {/* Queries Raised */}
                                            <td className="px-4 py-3">
                                                {data.queries.slice(0, expandedIndex === index ? data.queries.length : 2).map((query, idx) => (
                                                    <p key={idx}>{query.question}</p>
                                                ))}
                                                {data.queries.length > 2 && (
                                                    <p className="text-sm text-blue-600 cursor-pointer" onClick={() => toggleShowAll(index)}>
                                                        <a>{expandedIndex === index ? '(show less)' : '(read more)'}</a>
                                                    </p>
                                                )}
                                            </td>

                                            {/* Assistance */}
                                            <td className="px-4 py-3 text-[#2E2E2E]">
                                                {data.status}
                                                <br />
                                                <span className="text-sm">{new Date(data.createdAt).toLocaleTimeString()}</span>
                                            </td>

                                            {/* Actions */}
                                            <td className="px-4 py-3">
                                                <div className="flex items-center space-x-2">
                                                    <button
                                                        onClick={() => { handleOpenModel(data); }}
                                                        className="bg-[#313D4F] text-white px-3 py-1 rounded-full"
                                                        style={{ border: "2px solid #ECEEF6" }}>
                                                        View Tickets
                                                    </button>
                                                    <PiUploadLight size={20} />
                                                </div>
                                            </td>
                                        </tr>
                                    ))}
                            </tbody>
                        </table>
                    </div>
                </section>

            </div>
            <Modal open={open} onClose={handleClose}>
                <Box
                    className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white rounded-lg px-14 py-14 mx-auto  font-inter max-h-[500px] overflow-auto"
                    style={{
                        maxWidth: "900px",
                        outline: "none",
                        width: "90%",
                    }}
                >
                    {selectedModalData && (
                        <>
                            <div
                                style={{ border: "1px solid #979797" }}
                                className="absolute  h-[5.5rem] p-2 w-[15rem] rounded-md  right-5 top-2">
                                <p className=" text-[18px]">Assistance</p>
                                <p className="text-[18px] py-3 font-bold text-center">{new Date(selectedModalData.createdAt).toLocaleTimeString()}</p>
                            </div>
                            <div className="flex justify-between  items-start ">
                                <div className="max-w-[40%]">
                                    <h2 className="text-xl font-bold">{selectedModalData?.userId?.firstName && selectedModalData?.userId?.lastName
                                        ? `${selectedModalData?.userId?.firstName} ${selectedModalData?.userId?.lastName}`
                                        : "N/A"}</h2>
                                    <p className="text-[#646464] font-[18px]">{selectedModalData?.userId?.mobile || 'N/A'}</p>
                                    <p className="text-[#919191] font-[18px]">{selectedModalData?.userId?.email || 'N/A'}</p>
                                    <p className="text-[#919191] font-[18px]">Query Submitted: {new Date(selectedModalData.createdAt).toLocaleTimeString()}</p>
                                </div>

                            </div>
                            <div className="mt-4 w-full flex flex-row justify-between">
                                <div className="w-[50%]">
                                    <h3 className="font-bold text-lg">Queries Asked</h3>
                                    {selectedModalData.queries.map((query, idx) => (
                                        <p
                                            key={idx}
                                            className="border border-gray-200 p-2 mt-2 rounded-md w-full bg-[#F5F6FA]"
                                            style={{ border: "0.6px solid #D5D5D5" }}
                                        >
                                            {query.question}
                                        </p>
                                    ))}
                                </div>
                                {/* <div className="w-[45%]" >
                                    <p className="text-[18px]  font-bold">Documents</p>
                                    <div style={{ border: "1px solid #D8D8D8", boxShadow: "6px 6px 54px 0px #0000000D" }} className="h-[9rem] mt-2 rounded-xl flex justify-center items-center">
                                        <div style={{ border: "1px solid #888888" }} className="w-[120px] h-[80px] rounded-2xl grid place-content-center place-items-center ">

                                            <PiUploadLight />
                                            <p>Upload</p>
                                        </div>
                                    </div>
                                </div> */}
                            </div>

                        </>
                    )}
                </Box>
            </Modal>
            <Drawer anchor="left" open={drawerOpen} onClose={toggleDrawer(false)}> {/* Attach state to Drawer */}
                {DrawerList}
            </Drawer>
        </section>
    );
};

export default AdminDashboard;
