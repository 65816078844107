import React, { useEffect, useState, useCallback } from 'react';
import { useNavigate,useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { emptyCart, addAssitanceId, addToCartFromOtherRelavant } from '../../Slices/askQuerySlice';
import { baseUrl } from '../../utils/baseUrl';
import ScheduleAppointment from '../../Calendy/ScheduleAppointment';
import { useMediaQuery, Button, Dialog, DialogContent, DialogTitle, IconButton, Box, Typography, CircularProgress } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import MobileNavigation from './MobileNavigation';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';

const QueryTemplate = ({isTalkToExpert, activeSection, setIsTalkToExpert, setActiveSection }) => {
    const navigate = useNavigate()
    const dispatch = useDispatch();
    const location = useLocation();
    const [selectedId, setSelectedId] = useState(null);
    const [openModal, setOpenModal] = useState(false);
    const [selectdAnswer, setSelectdAnswer] = useState()
    const { otherRelevant, cart, assistanceId } = useSelector((state) => state.askQuery);
    const [isLoading, setIsLoading] = useState(false);

    const isMobile = useMediaQuery((theme) => theme.breakpoints.down('md'));
    const isAnswerSectionVisible = activeSection === 'answer' || !isMobile;
    const isMoreQueriesSectionVisible = activeSection === 'moreQueries' || !isMobile;

    const handleAddToQueryCart = useCallback((query) => {
        const otherQueries = sessionStorage.getItem('otherQueries') ? JSON.parse(sessionStorage.getItem('otherQueries')) : [];
        const updatedOtherQueries = otherQueries.filter(item => item._id !== query._id);

        sessionStorage.setItem('otherQueries', JSON.stringify(updatedOtherQueries));
        dispatch(addToCartFromOtherRelavant(query));
    }, [dispatch]);

    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const idFromQuery = queryParams.get("id");
        if (idFromQuery) {
            setSelectedId(idFromQuery);
        }
    }, [location]);

    const handleTakeExpertAssistance = async () => {
        if (assistanceId) {
            setOpenModal(true);
        } else {
            try {
                const token = window.localStorage.getItem("token");
                const response = await fetch(`${baseUrl}/user/logs-assistance-query`, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": `Bearer ${token}`
                    },
                    body: JSON.stringify({ queries: cart, bookedAssistance: false })
                });

                if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }

                const data = await response.json();
                dispatch(addAssitanceId(data.log._id));
                setOpenModal(true);  // Open the modal
            } catch (error) {
                console.error('Error fetching filtered data:', error);
            }
        }
    };


    useEffect(() => {
        const getAnswer = async () => {
            if (!selectedId) return;
            try {
                const response = await fetch(`${baseUrl}/user/explore-answer-my-question?question=${selectedId}`);

                if (!response.ok) {
                    setSelectdAnswer("");
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }

                const data = await response.json();
                setSelectdAnswer(data?.data);
            } catch (error) {
                console.error('Error fetching filtered data:', error);
            }
        };

        // getAnswer();
        const debounceTimer = setTimeout(getAnswer, 300);
        return () => clearTimeout(debounceTimer);
    }, [selectedId]);


    const handleCloseModal = () => {
        setOpenModal(false);
        // temporary
        const cart = [];
        dispatch(emptyCart())
        sessionStorage.setItem('cart', JSON.stringify(cart));
        navigate("/5")
    };

    const closeExpertModal = () => {
        setIsTalkToExpert(false);
    };


    const redirectToWhatsApp = () => {
        const cartItems = sessionStorage.getItem('cart') ? JSON.parse(sessionStorage.getItem('cart')) : [];
        setIsLoading(true);

        if (!cartItems.length > 0) {
            return
        }
        const selectedQueries = cartItems.map(item => item.question)
        const queryList = selectedQueries.join('\n');
        const encodedQueryList = encodeURIComponent(queryList);

        const whatsappURL = `https://api.whatsapp.com/send?phone=919137653851&text=I%20want%20to%20seek%20assistance%20for%20following%20queries%20%3A%0A${encodedQueryList}`;


        window.open(whatsappURL, '_blank');
        setIsLoading(false);
    };
    return (
        <>
            <section className='w-[100%] flex flex-col lg:flex-row '>
                {isAnswerSectionVisible && (
                    <div className="lg:max-w-[70%] w-[100%] min-h-[450px] mr-10 bg-white p-5  space-y-8 rounded-lg  scroll-smooth py-10  scrollbar-none">
                        <h1 className="lg:text-[1.8rem] font-inter text-[1.8rem] text-gray-800">{selectdAnswer?.question}</h1>
                        {(isMobile) &&
                            <MobileNavigation currentSection={activeSection} setActiveSection={setActiveSection} />
                        }

                        <section>

                            {selectdAnswer &&
                                <>
                                    <div
                                        className="text-[1rem] font-medium text-gray-700"
                                        dangerouslySetInnerHTML={{ __html: selectdAnswer?.answer }}
                                    />
                                </>}
                        </section>
                    </div>
                )}
                {isMoreQueriesSectionVisible && (
                    <aside className="lg:w-[35%] w-full h-auto  rounded-lg flex justify-center  lg:mt-0">
                        <div className="w-[90%] ">
                            {!isMobile &&
                                <div className='bg-[#0B3445] h-auto rounded-lg text-white max-h-[580px] p-5 flex flex-col gap-y-3 sticky top-[12vh] '>
                                    <h3 className='text-[1.1rem] font-bold'>Talk to Expert for Free</h3>
                                    <p className='text-[0.89rem] text-[#D8D8D8]'>
                                        Connect with our financial experts and get personalized help on your queries.   </p>
                                    <button
                                        onClick={handleTakeExpertAssistance}
                                        className=' py-3 rounded-full  bg-[#4880FF]  w-full' style={{
                                            boxShadow: "0px 4px 4px 0px #00000040",
                                        }}>
                                        Book an appointment
                                    </button>
                                    <button className='bg-[#20B486] py-3 rounded-full w-full flex items-center justify-center gap-2'
                                        style={{ boxShadow: "0px 4px 4px 0px #00000040" }}
                                        onClick={redirectToWhatsApp}
                                        disabled={isLoading}>
                                        <WhatsAppIcon />
                                        {isLoading ? <CircularProgress size={24} color="inherit" /> : "Contact us Now"}
                                    </button>

                                </div>
                            }

                            {(isMobile) &&
                                <MobileNavigation currentSection={activeSection} setActiveSection={setActiveSection} />
                            }
                            <div className="flex justify-around md:mt-0 mt-5 w-full  flex-col items-center  border-[#767677] bg-[#F8F8F8] py-[1.25rem] rounded-lg " style={{ boxShadow: "0px 4px 4px 0px #00000040" }}>
                                <p className="text-richgreen-800 text-[1rem] font-poppins font-semibold">Other Relevant Queries</p>
                                <div className="w-full  flex flex-col justify-evenly box-border">
                                    {otherRelevant && otherRelevant?.slice(0, 5).map((item, index) => (
                                        <p key={item._id} onClick={() => handleAddToQueryCart(item)} className='text-[1rem]  text-[#313638] flex items-center pl-2 pt-2 pb-1 border-[#767677] hover:bg-[#4880ff] hover:text-[#ffffff] cursor-pointer'
                                            style={{
                                                display: '-webkit-box',
                                                WebkitBoxOrient: 'vertical',
                                                overflow: 'hidden',
                                                textOverflow: 'ellipsis',
                                                // WebkitLineClamp: 2,
                                            }}>
                                            {item.question}
                                        </p>
                                    ))}

                                </div>
                            </div>
                        </div>
                    </aside>
                )}


                <Dialog open={isTalkToExpert && isMobile} onClose={closeExpertModal} maxWidth="sm" fullWidth>
                    <DialogTitle>
                        <Typography variant="h6">Talk to Expert for Free</Typography>
                        <Button onClick={closeExpertModal} color="inherit" style={{ position: 'absolute', right: 10, top: 10 }}>
                            &times;
                        </Button>
                    </DialogTitle>
                    <DialogContent>
                        <div className='bg-[#0B3445] h-auto rounded-lg text-white max-h-[580px] p-5 flex flex-col gap-y-3 '>
                            <h3 className='text-[1.1rem] font-bold'>Talk to Expert for Free</h3>
                            <p className='text-[0.89rem] text-[#D8D8D8]'>
                                Connect with our financial experts and get personalized help on your queries.   </p>
                            <button
                                onClick={handleTakeExpertAssistance}

                                className=' py-3 rounded-full  bg-[#4880FF]  w-full' style={{
                                    boxShadow: "0px 4px 4px 0px #00000040",

                                }}>
                                Book an appointment
                            </button>
                            <button className='bg-[#20B486] py-3 rounded-full w-full flex items-center justify-center gap-2'
                                style={{ boxShadow: "0px 4px 4px 0px #00000040" }}
                                onClick={redirectToWhatsApp}
                                disabled={isLoading}>
                                <WhatsAppIcon />

                                {isLoading ? <CircularProgress size={24} color="inherit" /> : "Contact us Now"}
                            </button>

                        </div>
                    </DialogContent>

                </Dialog>

                <Dialog open={openModal} onClose={handleCloseModal} fullWidth maxWidth="md">
                    <DialogTitle>
                        <Box sx={{ display: "flex", gap: "40px" }}>
                            <span>Schedule Appointment</span>
                        </Box>

                        <IconButton
                            aria-label="close"
                            onClick={handleCloseModal}
                            sx={{
                                position: 'absolute',
                                right: 8,
                                top: 8,
                                color: (theme) => theme.palette.grey[500],
                            }}
                        >
                            <CloseIcon />
                        </IconButton>
                    </DialogTitle>
                    <DialogContent>
                        <ScheduleAppointment />
                    </DialogContent>
                </Dialog>
            </section></>
    );
};

export default QueryTemplate;
