import React, { useEffect } from "react";
import trophy from "../../assests/icons/troffy.png";
import { emptyCart } from '../../Slices/askQuerySlice';
import { useDispatch } from 'react-redux';
import { useNavigate } from "react-router-dom";
import CloseIcon from '@mui/icons-material/Close';
import { Button } from "@mui/material";

const Congratulations = ({ setSchedule}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  
  const emptyMyCart = () => {
    dispatch(emptyCart());
  }

  useEffect(() => {
    emptyMyCart();
  }, []);

  const handleClose=()=>{
    setSchedule(false)
    navigate("/6")
  }
  return (
    <div className="relative p-10 bg-blue-100 flex flex-col items-center justify-center rounded-lg">
      <Button
        style={{
          position: 'absolute',
          top: '10px',
          right: '10px',
        }}
        onClick={() =>handleClose()}
      >
        <CloseIcon />
      </Button>
      <div className="text-center">
        {/* Icon and Header */}
        <div className="flex flex-col items-center mb-6">
          <h1 className="text-[64px] font-semibold">Congratulations</h1>
          <div className="w-[100px] h-[100px] bg-gray-200 flex items-center justify-center">
            <img
              src={trophy}
              alt="trophy"
              width={100}
              className="object-contain"
            />
          </div>
        </div>

        {/* Body Text */}
        <p className="text-[36px] mb-4 px-10">
          We have booked your 1 on 1 assistance call for
          07 August 2025 at 6:30 PM
        </p>
        <p className="text-[36px] mb-4 px-8">
          One of our Experts will reach out to you on the set time. Please be
          available on the scheduled time.
        </p>

        {/* Buttons */}
        <div className="flex justify-center space-x-4">
          <button className="bg-transparent text-[#045172] font-bold text-[18px] px-4 py-2 rounded-md hover:underline">
            Track Assistance
          </button>
          <button
            onClick={() => navigate("/6")}
            className="bg-[#045172] text-white font-medium px-4 py-2 rounded-full"
          >
            Explore More Queries
          </button>
        </div>
      </div>
    </div>
  );
};

export default Congratulations;
