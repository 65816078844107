import React, { useState } from 'react';
import { Button, TextField, CircularProgress } from '@mui/material';
import axios from 'axios';
import { baseUrl } from '../../utils/baseUrl';

const UploadQueryData = () => {
  const [file, setFile] = useState(null);
  const [isUploading, setIsUploading] = useState(false);
  const [error, setError] = useState('');
  const [successMessage, setSuccessMessage] = useState('');

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
    setError('');
    setSuccessMessage('');
  };

  const handleUpload = async () => {
    if (!file) {
      setError('Please select a file to upload.');
      return;
    }

    const formData = new FormData();
    formData.append('queryFile', file);
    // const baseUrl="http://localhost:8000/api/v1"
    try {
      setIsUploading(true);
      const response = await axios.post(`${baseUrl}/search-page-query/upload-search-query`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      setSuccessMessage(response.data.message);
      setIsUploading(false);
      setFile(null);
    } catch (err) {
      setError('Error uploading file. Please try again.');
      setIsUploading(false);
    }
  };

  return (
    <div>
      <h2>Upload Query Data</h2>
      <input
        accept=".xlsx, .xls"
        type="file"
        onChange={handleFileChange}
        style={{ marginBottom: '20px' }}
      />
      <div>
        <Button
          variant="contained"
          color="primary"
          onClick={handleUpload}
          disabled={isUploading}
        >
          {isUploading ? <CircularProgress size={24} /> : 'Upload'}
        </Button>
      </div>
      {error && <p style={{ color: 'red' }}>{error}</p>}
      {successMessage && <p style={{ color: 'green' }}>{successMessage}</p>}
    </div>
  );
};

export default UploadQueryData;
