import react from "react";
import Navbar from "../Components/Navbar";
import { Outlet } from "react-router-dom";

const Dashbaoard = () => {
    return (
        <div className="w-[100vw] h-[100vh]  ">
            <Navbar />
            <section className="w-10/12 mx-auto p-5">
               <Outlet></Outlet>
            </section>
        </div>
    )
}
export default Dashbaoard