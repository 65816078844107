import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  filters: {
    category: [],
    goals: ["helo"],
    profession: [],
    financialStatus: [],
    experience: [],
    personality: [], 
  },
};

const modifyFiltersSlice = createSlice({
  name: 'modifyFilters',
  initialState,
  reducers: {
    addModifyFilter: (state, action) => {
      const { filterType, value } = action.payload;

      if (filterType === 'personality') {
        state.filters.personality = [value];
      } else { 
        if (!state.filters[filterType].includes(value)) {
          state.filters[filterType].push(value);
        }
      }
    },
    removeModifyFilter: (state, action) => {
      const { filterType, value } = action.payload;
      state.filters[filterType] = state.filters[filterType].filter(item => item !== value);
    },
    clearModifyFilter: (state, action) => {
      const { filterType } = action.payload;
      state.filters[filterType] = [];
    },
    clearAllModifyFilters: (state) => {
      Object.keys(state.filters).forEach(filterType => {
        state.filters[filterType] = [];
      });
    },
  },
});

export const { addModifyFilter, removeModifyFilter, clearModifyFilter, clearAllModifyFilters } = modifyFiltersSlice.actions;

export default modifyFiltersSlice.reducer;
