import React, { useState, useRef } from "react";
import PhoneInput from "react-phone-input-2"
import 'react-phone-input-2/lib/style.css'
import Loginimg from "../assests/Login_imgs/loginpic.jpeg";
import { useSelector, useDispatch } from 'react-redux';
import { setSignupData } from "../Slices/authSlice";
import { useNavigate } from "react-router-dom";
import { setToken } from "../Slices/authSlice";
import { setUser } from "../Slices/profileSlice";
import { toast } from "react-hot-toast"
import { baseUrl } from "../utils/baseUrl";

const VerifyOtp = () => { 
    const { signupData } = useSelector((state) => state.auth);

    const [error, setError] = useState("");
    const [lastName, setLastName] = useState(signupData.lastName);
    const [name, setName] = useState(signupData.firstName);
    const [phoneNumber, setPhoneNumber] = useState(signupData.mobile);
    const [otp, setOtp] = useState(Array(6).fill(''));
    const inputs = useRef([]);
    const savedCart = sessionStorage.getItem('cart');
    const cart = savedCart ? JSON.parse(savedCart) : []

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const updateAssistance = async (cart) => {
        const totalAssistance = cart && cart.map(item => item._id);
    
        if (!totalAssistance || totalAssistance.length === 0) {
            console.error('No items found in the cart');
            return;
        }
    
        try {
            const response = await fetch(`${baseUrl}/search-page-query/update-assistance`, {
                method: 'PATCH',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ ids: totalAssistance }),
            });
    
            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }
            const data = await response.json();
            console.log('Assistance updated for the following items:', data);
        } catch (error) {
            console.error('Error updating assistance:', error);
        }
    };
    const handleChange = (e, index) => {
        const value = e.target.value;
        if (/^[0-9]$/.test(value)) {
            let newOtp = [...otp];
            newOtp[index] = value;
            setOtp(newOtp);

            if (index < inputs.current.length - 1 && value) {
                inputs.current[index + 1].focus();
            }
        } else if (value === '') {
            if (index > 0) {
                inputs.current[index - 1].focus();
            }
        }
    };

   

    const handleVerifyOtpClick =async () => {
        try {

            const signupData = {
                mobileNumber: phoneNumber,
                otp: otp.join(''),
            };

            dispatch(setSignupData(signupData));
            const response = await fetch(`${baseUrl}/user/verify/signup-otp`, {
                method: 'PATCH',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(signupData),
            });

            const data = await response.json();

            if (!response.ok) {
                const errorMessage = data?.message || "Failed to verify OTP";
                toast.error(errorMessage);
                throw new Error(errorMessage);
            } else {
                dispatch(setUser(data));
                dispatch(setToken(data?.user?.token));

                localStorage.setItem("token",data?.user?.token ||"");
                localStorage.setItem("isDetailFilled",data?.user?.isDetailFilled);
                localStorage.setItem("finright_email",data?.user?.email || "");
                localStorage.setItem("user", data?.user ? JSON.stringify(data?.user) : "");
                toast.success(data.message);
                console.log("Saved token and user data to localStorage");
                setError("");
                setName("");
                setLastName("");
                setPhoneNumber("");
                setOtp(Array(6).fill(''));
                await updateAssistance(cart)
                navigate("/queries")
            }
        } catch (error) {
            console.error("Couldn't verify OTP", error.message);
            toast.error("Failed to verify OTP. Please try again.");
        }
    };


    return (
        <div className="h-screen flex justify-center items-center bg-richgreen-5">
            <div className="lg:w-[70vw] lg:h-[65vh] border border-gray-300 bg-white rounded-2xl flex lg:justify-center items-center md:flex-col">
                <div className="flex flex-row w-full justify-between  px-[3em]">
                    <div className="lg:max-w-[25vw]">
                        <h3 className="text-[1.5em] font-bold font-poppins text-gray-900 mb-4 max-w-[25em]">
                            Get your answer to your query’s
                        </h3>
                        <p className="text-[1.2em] font-semibold font-poppins text-gray-600 mb-4 max-w-[25rem]">
                            Sign up to know your answer
                        </p>
                        <form className="w-[95%] flex flex-col gap-3 font-poppins">
                            <div className="flex w-full gap-[1em]">
                                <div className="mb-2 w-full">
                                    <label htmlFor="firstName" className="block text-[0.8em] text-[#666666]">
                                        First Name
                                    </label>
                                    <input
                                        id="firstName"
                                        type="text"
                                        className="w-full p-1.5 border text-black border-gray-300 rounded-xl shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
                                        value={name}
                                        onChange={(e) => setName(e.target.value)}
                                    />
                                </div>
                                <div className="mb-2 w-full">
                                    <label htmlFor="lastName" className="block text-[0.8em]  text-[#666666]">
                                        Last Name
                                    </label>
                                    <input
                                        id="lastName"
                                        type="text"
                                        className="w-full text-[0.75rem] p-1.5 border text-black border-gray-300 rounded-xl shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
                                        value={lastName}
                                        onChange={(e) => setLastName(e.target.value)}
                                    />
                                </div>
                            </div>
                            <div className="w-full ">
                                <label htmlFor="phoneNumber" className="block text-[0.8em] text-[#666666]">Phone Number</label>
                                <div>
                                    <PhoneInput
                                        country={'in'}
                                        value={phoneNumber}
                                        onChange={setPhoneNumber}
                                        containerClass="w-full rounded-xl"
                                        inputStyle={{ width: '100%', borderRadius: "12px" }}
                                        inputClass="w-full text-[0.75rem]  py-3 pr-30 rounded-lg text-black text-[20px] focus:outline-none  "
                                    />
                                </div>
                                {error && <p className="text-[0.6rem] text-red-500 mt-2">{error}</p>}
                            </div>
                            <div className="flex justify-center">
                                <div className="flex space-x-[1rem]">
                                    {otp.map((value, index) => (
                                        <input
                                            key={index}
                                            ref={(el) => (inputs.current[index] = el)}
                                            type="text"
                                            maxLength="1"
                                            value={value}
                                            onChange={(e) => handleChange(e, index)}
                                            className="w-[2.8rem] aspect-square text-center border text-black border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                                        />
                                    ))}
                                </div>
                            </div>
                        </form>
                        <div className="w-[95%] mt-2">
                            <button onClick={handleVerifyOtpClick}
                                className="py-1 border-2 w-full border-[#0F5426] font-inter  rounded-2xl text-[1em] px-10 text-[#F2FFF3] bg-[#045172]"
                            > SignUp
                            </button>
                        </div>
                        <section className="w-[95%] flex flex-col gap-y-[1rem]">
                            <p className="text-center w-full text-[0.7em] font-inter">
                                By signing up you agree to FinRight’s <span className="text-blue-400">Terms & Conditions</span> and <span className="text-blue-400">Privacy Policy</span> and agree to receive notifications & communication via WhatsApp.
                            </p>

                            <p className="text-center w-full text-[0.7em] font-inter">Already have an account? <span className="text-blue-500 cursor-pointer" onClick={() => navigate("/user/login")}>login</span></p>
                        </section>
                    </div>
                    <div className="w-[50%] h-full flex justify-center items-center">
                        <img src={Loginimg} alt="Login" className="h-[35vh] w-[35vw] object-cover" />
                    </div>  </div>
            </div>
        </div>
    );
};

export default VerifyOtp; 
