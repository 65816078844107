
const BASE_URL="https://discoverqueries.finright.in/api/v1" 
// const BASE_URL="http://localhost:8000/api/v1" 

export const AuthEndPoints={
    GETSTART_API:BASE_URL+"/user/get-start",
    VERIFY_API:BASE_URL+"/user/verify/login-otp",
    SIGNUP_API:BASE_URL+"/user/signup",
    LOGIN_API:BASE_URL+"/user/login"
}


