import React, { useState } from 'react';
import { List, ListItem, ListItemIcon, ListItemText, IconButton, Divider } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import { Menu as MenuIcon, Person, QuestionAnswer, UploadFile, HelpOutline } from '@mui/icons-material';
import LogoutIcon from '@mui/icons-material/Logout';

const SidebarContainer = styled('div')(({ theme, open }) => ({
  width: open ? 240 : 60,
  transition: 'width 0.3s ease',
  height: '100vh',
  display: 'flex',
  flexDirection: 'column',
  // justifyContent: 'space-between',
  backgroundColor: theme.palette.background.paper,
}));

const CollapsedItem = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  padding: '10px',
});

const AdminSidebar = () => {
  const [isOpen, setIsOpen] = useState(true);
  const navigate = useNavigate();

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  const handleMenuClick = (path) => {
    navigate(path);
    // setIsOpen(false);
  };

  const handleLogoutClick = (path) => {
    window.localStorage.removeItem("admin_token")
    navigate(path);
  }

  const menuItems = [
    { text: 'Admin Profile', icon: <Person />, path: '/cms/panel/admin/dashboard' },
    { text: 'Manage Question', icon: <QuestionAnswer />, path: '/cms/panel/manage-query' },
    { text: 'Delete Question', icon: <QuestionAnswer />, path: '/cms/panel/delete-question' },
    { text: 'Upload Answer', icon: <UploadFile />, path: '/cms/panel/add-answer' },
    { text: 'Upload Question', icon: <UploadFile />, path: '/cms/panel/upload-question' },
    { text: 'Manage Filters', icon: <UploadFile />, path: '/cms/panel/remove-add-filter' },
    // { text: 'Manage FAQ', icon: <HelpOutline />, path: '/cms/panel/admin/manage-Q&A' },
  ];

  return (
    <SidebarContainer open={isOpen}>
      {/* Toggle button for sidebar */}
      <CollapsedItem>
        <IconButton onClick={toggleSidebar}>
          <MenuIcon />
        </IconButton>
      </CollapsedItem>

      {/* Menu List */}
      <List>
        {menuItems.map((item, index) => (
          <ListItem
            button
            key={index}
            onClick={() => handleMenuClick(item.path)}
            sx={{ justifyContent: isOpen ? 'initial' : 'center' ,cursor:"pointer"}}
          >
            <ListItemIcon>{item.icon}</ListItemIcon>
            {isOpen && <ListItemText primary={item.text} />}
          </ListItem>
        ))}


      </List>

      <Divider />
      <ListItem
        button
        onClick={() => handleLogoutClick("/cms/panel/admin/login.dev")}
        sx={{ justifyContent: isOpen ? 'initial' : 'center' }}
      >
        <ListItemIcon><LogoutIcon /></ListItemIcon>
        {isOpen && <ListItemText primary={"Log Out"} />}
      </ListItem>
      {/* You can add footer or additional controls if needed */}
    </SidebarContainer>
  );
};

export default AdminSidebar;
