import React, { useState, useRef, useEffect } from "react";
import PhoneInput from "react-phone-input-2";
import 'react-phone-input-2/lib/style.css';
import Loginimg from "../assests/Login_imgs/loginpic.jpeg";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { login_A, VerifyOtp_A } from "../Services/Operations/authApi";
import { baseUrl } from "../utils/baseUrl";
import { setToken } from "../Slices/authSlice";
import { setUser } from "../Slices/profileSlice";
import { toast } from "react-hot-toast";

const SignIn = () => {
    const [mobile, setMobile] = useState('');
    const [error, setError] = useState("");
    const [sentOtp, setSentOtp] = useState(false);
    const [otp, setOtp] = useState(Array(6).fill(''));
    const [cooldown, setCooldown] = useState(0);
    const inputs = useRef([]);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        if (cooldown > 0) {
            const timer = setTimeout(() => {
                setCooldown(cooldown - 1);
            }, 1000);
            return () => clearTimeout(timer);
        }
    }, [cooldown]);

    const handleChange = (e, index) => {
        const value = e.target.value;

        if (/^[0-9]$/.test(value)) {
            const newOtp = [...otp];
            newOtp[index] = value;
            setOtp(newOtp);

            // Move focus to next input if the current one is filled
            if (index < inputs.current.length - 1) {
                inputs.current[index + 1].focus();
            }
        } else if (value === '') {
            // Clear the current value and move to the previous input on backspace
            const newOtp = [...otp];
            newOtp[index] = '';
            setOtp(newOtp);

            if (index > 0) {
                inputs.current[index - 1].focus();
            }
        }
    };

    const handleOtpSent = async () => {
        if (mobile.length < 10) {
            setError("Please enter a valid phone number.");
            return;
        }
        try {
            const response = await dispatch(login_A(mobile));
            if (response?.status !== 200 && response?.status !== 201) {
                setError(response?.message || "Failed to send OTP. Please try again.");
                throw new Error(response?.message || "Failed to send OTP. Please try again.");
            }
            setError("");
            setSentOtp(true);
            setCooldown(30);
        } catch (error) {
            setError(error?.message || "An error occurred. Please try again.");
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (mobile.length < 10) {
            setError("Please enter a valid phone number.");
            return;
        }

        const loginData = {
            mobileNumber: mobile,
            otp: otp.join(''),
        };

        try {
            const response = await fetch(`${baseUrl}/user/verify/login-otp`, {
                method: "PATCH",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(loginData),
            });

            const responseData = await response.json();

            if (response.ok) {
                dispatch(setUser(responseData?.user));
                dispatch(setToken(responseData?.user?.token));

                localStorage.setItem("token", responseData?.user?.token || "");
                localStorage.setItem("isDetailFilled", responseData?.user?.isDetailFilled);
                localStorage.setItem("finright_email", responseData?.user?.email || "");
                localStorage.setItem("user", responseData?.user ? JSON.stringify(responseData?.user) : "");

                setError("");
                setOtp(Array(6).fill(''));
                setMobile("");

                toast.success(responseData?.message || "OTP Verified Successfully");
                const redirectTo = location?.state?.redirectTo ? location.state.redirectTo : "/2";
                navigate(redirectTo);
            } else {
                const errorMessage = responseData?.message || "Failed to verify OTP";
                toast.error(errorMessage);
                throw new Error(errorMessage);
            }
        } catch (error) {
            console.error("Couldn't verify OTP", error.message);
            toast.error("Failed to verify OTP. Please try again.");
        }
    };

    return (
        <section className="min-h-screen flex justify-center items-center bg-richgreen-5">
            <section className="flex flex-col w-full lg:w-[70vw]  lg:flex-row justify-evenly overflow-hidden mb-5 border border-gray-300 bg-white rounded-2xl">
                <section className="flex lg:flex-row flex-col-reverse w-full gap-4 h-full items-center">
                    <div className="flex gap-[15px] lg:w-[25vw] md:w-[80%] sm:w-[80%] justify-between flex-col lg:gap-y-10 w-[100%] lg:px-[1.5rem] items-center mx-auto lg:items-center lg:justify-center p-[10px]">
                        <form className="flex font-roboto flex-col lg:flex-col lg:max-w-[25vw] w-full px-[2px] justify-center items-center">
                            <div>
                                <h1 className="font-rich lg:text-[2.5rem] text-[30px] text-[#0C1421] font-bold text-start font-sfPro py-2">
                                    Welcome Back
                                </h1>
                                <p className="text-[17.50px] lg:text-[0.8rem] text-[#313957] font-poppins pb-5">
                                    Today is a new day. It's your day. You shape it. Sign in to get answers to your queries.
                                </p>
                            </div>
                            <div className="w-full ">
                                <label htmlFor="phoneNumber" className="block text-[16px] lg:text-[0.8rem] text-[#666666]">Phone Number</label>
                                <div>
                                    <PhoneInput
                                        country={'in'}
                                        value={mobile}
                                        onChange={setMobile}
                                        containerClass="w-full rounded-xl"
                                        inputStyle={{ width: '100%', borderRadius: "12px" }}
                                        inputClass="w-full py-6 lg:py-5 pr-30 rounded-lg text-black text-[20px] focus:outline-none "
                                    />
                                </div>
                                {error && <p className="text-red-500 mt-2">{error}</p>}
                            </div>
                            {sentOtp && (
                                <div className="flex w-full justify-center pt-10">
                                    <div className="flex w-full max-w-xs sm:max-w-md gap-2 sm:gap-3">
                                        {otp.map((value, index) => (
                                            <input
                                                key={index}
                                                ref={(el) => (inputs.current[index] = el)}
                                                type="text"
                                                maxLength="1"
                                                value={value}
                                                onChange={(e) => handleChange(e, index)}
                                                className="flex-grow w-[16.6%] aspect-square text-center border text-black border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                                            />
                                        ))}
                                    </div>
                                </div>
                            )}

                        </form>

                        <div className="w-[100%] lg:text-center">
                            {!sentOtp ? (
                                <button
                                    onClick={handleOtpSent}
                                    className="lg:py-[0.5rem] w-full text-[18px] lg:text-[0.9em] py-3 lg:w-full bg-[#162D3A] rounded-[20px] text-richgreen-5 font-inter"
                                    style={{ border: "1px solid #0F5426" }}
                                    disabled={cooldown > 0}
                                >
                                    {cooldown > 0 ? `Resend OTP in ${cooldown}s` : "Get OTP"}
                                </button>
                            ) : (
                                <button
                                    onClick={handleSubmit}
                                    className="lg:py-[0.5rem] w-full text-[18px] lg:text-[0.9em] py-3 lg:w-full bg-[#162D3A] rounded-[20px] text-richgreen-5 font-inter"
                                    type="submit"
                                    style={{ border: "1px solid #0F5426" }}
                                >
                                    Sign In
                                </button>
                            )}
                            <p className="text-[#122B31] text-[18px] pl-5 py-2 text-center font-inter lg:text-[0.7em]">
                                Don't have an account?{" "}
                                <Link to="/user/signup">
                                    <span className="text-[#1E4AE9]">Sign Up</span>
                                </Link>
                            </p>
                            <p
                                onClick={() => navigate("/")}
                                className="text-blue-600 text-[0.7em] cursor-pointer">Go to Home</p>
                        </div>
                        <p className="text-center mb-10 lg:text-[0.7em]">© 2023 ALL RIGHTS RESERVED</p>
                    </div>

                    <div className="flex lg:h-full lg:w-[40.5%] justify-center w-[67.5%] h-[150px] mx-auto">
                        <img
                            src={Loginimg}
                            className="lg:w-[100%] lg:h-fit w-[217.5px] h-[142.5px] object-cover"
                            alt="Cover"
                        />
                    </div>
                </section>
            </section>
        </section>
    );
};

export default SignIn;
