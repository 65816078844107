import React from 'react';
import UTurnLeftIcon from '@mui/icons-material/UTurnLeft';
import ReadMoreIcon from '@mui/icons-material/ReadMore';

const MobileNavigation = ({ currentSection, setActiveSection }) => {
  const buttons = [
    { label: 'Answer', icon: <UTurnLeftIcon className="transform rotate-90" />, section: 'answer' },
    { label: 'My List', icon: <UTurnLeftIcon className="transform rotate-90" />, section: 'myQueries' },
  ];

  return (
    <div className='flex space-x-2 justify-evenly'>
      {buttons
        .filter(button => button.section !== currentSection) // Filter out the current active section
        .map((button, index) => (
          <button
            key={index}
            className={`flex items-center whitespace-nowrap border border-[#dadada] rounded-[20px] p-2 text-sm ${
              currentSection === button.section ? 'opacity-50 cursor-not-allowed' : ''
            }`}
            onClick={() => setActiveSection(button.section)}
            disabled={currentSection === button.section} // Disable the button if it's the current section
          >
            {button.icon} {button.label}
          </button>
        ))}
    </div>
  );
};

export default MobileNavigation;
