import {baseUrl} from './baseUrl'
const updateAssistance = async (cart) => {
    const totalAssistance = cart && cart.map(item => item._id);

    if (!totalAssistance || totalAssistance.length === 0) {
        return;
    }

    try {
        const response = await fetch(`${baseUrl}/search-page-query/update-assistance`, {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ ids: totalAssistance }),
        });

        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }
        // const data = await response.json();
    } catch (error) {
        console.error('Error updating assistance:', error);
    }
};

const encodeFiltersToQueryString = (filters, searchText, initialFilters) => {
    const query = new URLSearchParams();

    if (searchText && searchText.length > 0) {
        query.append('searchText', searchText);
        return query.toString();
    }

    Object.keys(filters).forEach((filterType) => {
        if (filters[filterType].length > 0) {
            query.append(filterType, filters[filterType].join(','));
        }
    });
    return query.toString();
};

const decodeQueryStringToFilters = (queryString) => {
    const params = new URLSearchParams(queryString);
    const filters = {};
    for (let [key, value] of params.entries()) {
        filters[key] = value.split(',');
    }
    return filters;
};

export {
    decodeQueryStringToFilters,
    encodeFiltersToQueryString,
    updateAssistance
}
