import React, { useState } from "react";
import logo from "../assests/logo.png";
import { Box, Stepper, Step, StepLabel, Stack, Typography, IconButton } from "@mui/material";
import { useLocation } from 'react-router-dom';

import MenuIcon from '@mui/icons-material/Menu';
import { Link } from "react-router-dom";
import { steps } from "../data/Navbar/steps";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";


const Navbar = ({ setQuery }) => {

    const location = useLocation();

    // Determine the active step based on the current path
    const getActiveStep = () => {
        if (location.pathname === '/queries') {
            return 1; 
        } else if (location.pathname === 'take-expert') {
            return 2;
        }
        return 0; 
    };

    const activeStep = getActiveStep();


    const { step } = useParams();

    const navigate=useNavigate();
    
    const [home, setHome] = useState(step);
    return (
        <Box component="nav" sx={{boxShadow: 2, bgcolor: "white",position:"sticky",top:"0",zIndex:"9" }}>
            <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                sx={{   height: {xs: '6vh',md: '11vh',}, px: { xs: 2, lg: 10 },}}
            >
                {/* Logo */}
                <Box
                    component="img"
                    src={logo}
                    alt="Logo"
                    sx={{ cursor: "pointer", height: { xs: 20, sm: 30 }, width: "auto" }}
                    onClick={() =>navigate("/1")}
                />

                {/* Stepper (only visible on large screens) */}
                {
                    home === 1 ? null : (
                      <div className="w-[50%]">
                          <Box sx={{ height: "100%",width:"100%", display: { xs: "none", lg: "block",md:"block" } }}>
                            <Stack sx={{height: "30%" }}>
                                <Box>
                                    <Stepper activeStep={activeStep} alternativeLabel sx={{ fontSize: "0.25rem" }}>
                                        {steps.map((label, index) => (
                                            <Step key={index} sx={{ width: "1rem" }}>
                                                <StepLabel sx={{ '& .MuiStepLabel-label': { fontSize: '0.775rem' } }}>{label}</StepLabel>
                                            </Step>
                                        ))}
                                    </Stepper>
                                </Box>
                            </Stack>
                        </Box>
                  </div>
                    )
                }


                {/* Menu Icon (visible on small screens) */}
                {/* <IconButton
                    edge="start"
                    color="inherit"
                    aria-label="menu"
                    sx={{ display: { lg: "none" }, fontSize: "50px" }}
                >
                    <MenuIcon />
                </IconButton> */}
            </Stack>
        </Box>
    );
};

export default Navbar;
