import React from 'react'
import {BottomNavigation, BottomNavigationAction } from '@mui/material';
import AutoAwesomeOutlinedIcon from '@mui/icons-material/AutoAwesomeOutlined';
import SortOutlinedIcon from '@mui/icons-material/SortOutlined';
import BookmarksOutlinedIcon from '@mui/icons-material/BookmarksOutlined';
import Badge from '@mui/material/Badge';

const QueryPageMobileMenu = ({activeSection,handleBottomNavigationChange,handleFindForMe,handleFilter,handleGetAnswerClick,cartItemCount}) => {
    return (
        <BottomNavigation
            value={activeSection}
            onChange={handleBottomNavigationChange}
            className="fixed bottom-0 left-0 w-full sm:hidden"
        >
            <BottomNavigationAction
                label="Find Query"
                showLabel={true}
                onClick={() => handleFindForMe()}
                icon={<AutoAwesomeOutlinedIcon />}
            />
            <BottomNavigationAction
                label="Add Filter"
                showLabel={true}
                onClick={() => handleFilter()}
                icon={<SortOutlinedIcon />}
            />
            <BottomNavigationAction
                label="My List"
                showLabel={true}
                onClick={handleGetAnswerClick}
                icon={
                    <Badge
                        badgeContent={cartItemCount}
                        color="primary"
                    >
                        <BookmarksOutlinedIcon />
                    </Badge>
                }
            />
        </BottomNavigation>
    )
}

export default QueryPageMobileMenu
