import React from 'react';
import { Box, Typography, Avatar, Badge } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';

const stepData = [
  {
    id: 1,
    label: "Primary Answer",
    bgColor: "green",
    icon: <CheckCircleIcon sx={{ color: 'green' }} />,
    lineColor: "orange"
  },
  {
    id: 2,
    label: "Book Consultancy",
    bgColor: "orange",
    icon: <ErrorOutlineIcon sx={{ color: 'orange' }} />,
    lineColor: "gray"
  },
  {
    id: 3,
    label: "Detailed Analysis",
    bgColor: "[#E0E0E0]",
    icon: <RemoveCircleIcon sx={{ color: 'red' }} />,
    borderColor: "red",
    lineColor: "gray"
  },
  {
    id: 4,
    label: "Resolution",
    bgColor: "[#E0E0E0]",
    icon: <RemoveCircleIcon sx={{ color: 'red' }} />,
    borderColor: "red",
    lineColor: "gray"
  }
];

export default function CustomizedSteppers() {
  return (
    <Box display="flex" alignItems="center" justifyContent="center">
      {stepData.map((step, index) => (
        <React.Fragment key={step.id}>
          <Box display="flex" flexDirection="column" alignItems="center">
            <Badge
              badgeContent={step.icon}
              overlap="circular"
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                borderRadius: "50%", // Keep circular shape
                bgcolor: step.bgColor,
                border: step.borderColor ? `2px solid ${step.borderColor}` : "none",
                width: 65,
                height: 65,
              }}
            >
              <Typography
                sx={{
                  fontSize: '0.675rem',
                  color: step.bgColor === "[#E0E0E0]" ? "black" : "white", // Conditional color change based on background
                  fontWeight: 'semibold', // You can adjust the weight if needed
                  textAlign: 'center', // Center the text
                  width: '100%', // Ensure typography takes full width for centering
                }}
              >
                {step.label}
              </Typography>
            </Badge>
          </Box>

          {/* Line between steps */}
          {index !== stepData.length - 1 && (
            <Box
              sx={{ display: "" }}
              width="80px"
              height="2px"
              bgcolor={step.lineColor}
              mx={1}
            />
          )}
        </React.Fragment>
      ))}
    </Box>
  );
}
