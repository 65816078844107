import React, { useState } from "react";
import PhoneInput from "react-phone-input-2"
import 'react-phone-input-2/lib/style.css'
import Loginimg from "../assests/Login_imgs/loginpic.jpeg";
import {useDispatch } from 'react-redux';
import { setSignupData } from "../Slices/authSlice";
import { useNavigate } from "react-router-dom";
import { toast } from "react-hot-toast"
import { baseUrl } from "../utils/baseUrl";


const Signup = () => {

    const [error, setError] = useState("");
    const [lastName, setLastName] = useState("");
    const [name, setName] = useState("");
    const [phoneNumber, setPhoneNumber] = useState('');
    const dispatch = useDispatch();
    const token =window.localStorage.getItem("token")
    const navigate = useNavigate();

    const handleGetOtpClick =async () => {
        if (!name.trim()) {
            setError("Please enter your first name.");
            return;
        }
        if (!lastName.trim()) {
            setError("Please enter your last name.");
            return;
        }
        if (phoneNumber.length < 10) {
            setError("Please enter a valid phone number.");
            return;
        }
        const signupdata = {
            firstName: name,
            lastName: lastName,
            mobile: phoneNumber,
            password: "narendra@123"
        };

        dispatch(setSignupData(signupdata));
        try {
            const response = await fetch(`${baseUrl}/user/signup`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`,
                },
                body: JSON.stringify(signupdata), 
            });

            const result = await response.json(); 
 
            if (!response.ok) {
                throw new Error(result.message || "Error in sending OTP");
            }
            localStorage.setItem("isDetailFilled", result?.user?.isDetailFilled);
            toast.success(result.message);
            setError("");
            navigate("/user/verify/login-otp",{ state: { redirectTo: "/queries" } });
        } catch (error) {
            console.log("Error:", error);
            toast.error(error.message);
        }
    };

    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            handleGetOtpClick();
        }
    };
    return (
        <div className="min-h-screen flex justify-center items-center bg-richgreen-5 ">
            <div className=" lg:w-[70vw] lg:h-[65vh] h-[100vh] border border-gray-300 bg-white rounded-2xl flex lg:justify-center items-center md:flex-col">
                <div className="flex lg:flex-row w-full flex-col-reverse lg:justify-between justify-evenly
                 h-[90%]  lg:px-[3em] px-[2em]">
                    <div className="lg:max-w-[25vw]">
                        <h3 className="text-[1.5em] lg:text-start text-center font-bold font-poppins text-gray-900 mb-4 max-w-[25em]">
                            Get your answer to your query’s
                        </h3>
                        <p className="text-[1.2em] font-semibold lg:text-start text-center font-poppins text-gray-600 mb-4 max-w-[25rem]">
                            Sign up to know your answer
                        </p>
                        <form className="w-[95%] flex flex-col gap-3 font-poppins" onKeyDown={handleKeyDown}>
                            <div className="flex lg:flex-row flex-col w-full gap-[1em]">
                                <div className="mb-2 w-full">
                                    <label htmlFor="firstName" className="block text-[0.8em] text-[#666666]">
                                        First Name
                                    </label>
                                    <input
                                        id="firstName"
                                        type="text"
                                        className="w-full lg:p-1.5  p-2.5 border text-black border-gray-300 rounded-xl shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 text-[0.75rem]"
                                        value={name}
                                        onChange={(e) => setName(e.target.value)}
                                    />
                                </div>

                                <div className="mb-2 w-full">
                                    <label htmlFor="lastName" className="block text-[0.8em]  text-[#666666]">
                                        Last Name
                                    </label>
                                    <input
                                        id="lastName"
                                        type="text"
                                        className="w-full lg:p-1.5 p-2.5 border text-black border-gray-300 rounded-xl shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 text-[0.75rem]"
                                        value={lastName}
                                        onChange={(e) => setLastName(e.target.value)}
                                    />
                                </div>
                            </div>
                            <div className="w-full mb-5">
                                <label htmlFor="phoneNumber" className="block text-[0.8em] text-[#666666]">Phone Number</label>
                                <PhoneInput
                                    country={'in'}
                                    value={phoneNumber}
                                    onChange={setPhoneNumber}
                                    containerClass="w-full"
                                    inputProps="w-[500px]"
                                    inputStyle={{ width: '100%', borderRadius: "12px" }}
                                    inputClass="w-full lg:py-3 py-5 text-black text-[20px] focus:outline-none  "
                                />
                                {error && <p className="text-[0.6rem] text-red-500 mt-2">{error}</p>}
                            </div>
                        </form>
                        <div className="w-[95%] mt-2">
                            <button
                                onClick={handleGetOtpClick}
                                className="py-1 lg:py-[0.5rem] border-2 w-full border-[#0F5426] font-inter  rounded-2xl lg:text-[0.9em] text-[1rem] px-10 text-[#F2FFF3] bg-[#045172]"
                            > Get OTP
                            </button>
                        </div>
                        <section className="w-[95%] flex flex-col gap-y-[1rem]">
                            <p className="text-center w-full text-[0.7em] font-inter">
                                By signing up you agree to FinRight’s <span className="text-blue-400">Terms & Conditions</span> and <span className="text-blue-400">Privacy Policy</span> and agree to receive notifications & communication via WhatsApp.
                            </p>
                            <p className="text-center w-full text-[0.7em] font-inter">Already have an account? <span className="text-blue-500 cursor-pointer" onClick={() => navigate("/user/login",{ state: { redirectTo: "/queries" }})}>login</span></p>
                            <p
                                onClick={() => navigate("/1")}
                                className="text-blue-600 text-[0.7em]  cursor-pointer">Go to Home</p>
                        </section>
                    </div>
                    <div className="lg:w-[50%] lg:h-full h-[15vh] flex justify-center items-center">
                        <img src={Loginimg} alt="Login" className="lg:h-[35vh] w-[60vw] h-[20vh] lg:w-[35vw] object-cover" />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Signup; 
