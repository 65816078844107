import React, { useState } from "react";
import { buttonIcon } from "../../../data/UserDashboard";
import { buttonData } from "../../../data/UserDashboard";
import CustomizedSteppers from "./Stepper";
import FeedbackForm from "./FeedBackModel";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from "axios";
import { baseUrl } from "../../../utils/baseUrl";


const QueryCard = ({ userAssistanceData }) => {
    const [isFeedbackOpen, setIsFeedbackOpen] = useState(false);
    const [selectedFeedbackId, setSelectedFeedbackId] = useState(null);

    const handleFeedBack = (id) => {
        setSelectedFeedbackId(id);
        setIsFeedbackOpen(true);
    };

    const handleCloseFeedback = () => {
        setIsFeedbackOpen(false);
    };

    const handleSubmitFeedback = async (id, feedback, onSuccess) => {
        console.log("Feedback submitted for ID:", id, "Feedback:", feedback);
        const token = window.localStorage.getItem("token");
        try {
            const response = await axios.patch(`${baseUrl}/user/leave-feedback/${id}`,
                { feedback: feedback },
                {
                    headers: {
                        "Authorization": `Bearer ${token}`,
                        "Content-Type": "application/json"
                    }
                });

            console.log('Feedback submitted successfully:', response.data);
            toast.success('Feedback submitted successfully:');
            if (onSuccess) onSuccess();
        } catch (error) {
            console.error('Error submitting feedback:', error);
            toast.error(`Failed to upload answer. ${error?.message}`);
        }
    };


    return (
        <section className="sm:w-[100vw] lg:w-[100%] border[#D8D8D8]"
        >
            <ToastContainer />

            {userAssistanceData && userAssistanceData.map((assistance, index) => (
                <div key={assistance._id} className="bg-[#FBF7F7] shadow-md rounded-2xl font-inter max-[] mb-2">
                    <div className="p-5">
                        <div className="hidden lg:block">
                            <p className="font-semibold"> {index === 0 ? "Last Assessment" : "Previous Assessment"}</p>
                            <p className="text-gray-500">{new Date(assistance.createdAt).toLocaleDateString()}</p>
                        </div>
                        <div>
                            <CustomizedSteppers />
                        </div>
                    </div>
                    <div
                        className="bg-[#FFFFFF] rounded-2xl grid lg:grid-cols-[70%,30%] grid-cols-1 lg:p-7 px-5 py-6 border-[#D8D8D8] border-[2px]"
                        style={{ boxShadow: "0px 12px 16px -4px #00000014" }}
                    >
                        <div className="">
                            <div className="flex justify-between">
                                <div>
                                    <p className="text-[14px] font-semibold text-[#FF0000]">Book assistance now to resolve financial queries.</p>
                                </div>
                            </div>
                            <div className="mt-4 max-w-[95%]">
                                <p className="font-bold text-[16px]">Your Queries</p>

                                {assistance.queries.map((item, index) => (
                                    <p className="text-[17px] lg:text-[20px] font-bold mb-2" key={index}
                                        style={{
                                            display: '-webkit-box',
                                            WebkitBoxOrient: 'vertical',
                                            overflow: 'hidden',
                                            textOverflow: 'ellipsis',
                                            WebkitLineClamp: 2,
                                        }}>
                                        {item.question}
                                    </p>
                                ))}
                            </div>
                            {assistance.leaveFeedback &&
                                <div>
                                    <p className="text-[14px] font-semibold text-[#008000]">My FeedBack :---------{assistance.leaveFeedback}</p>
                                </div>
                            }

                        </div>
                        <div className=" lg:flex-col hidden lg:flex space-y-4">

                            <button key={index} className="bg-[#FBF7F7] text-[16px]   text-black px-4 py-2 rounded border-[#D8D8D8] border-[1px]"
                                style={{ boxShadow: "0px 12px 16px -4px #00000014" }}>
                                Book Assistance
                            </button>

                            <button key={index} className="bg-[#FBF7F7] text-[16px]   text-black px-4 py-2 rounded border-[#D8D8D8] border-[1px]"
                                style={{ boxShadow: "0px 12px 16px -4px #00000014" }} onClick={() => handleFeedBack(assistance._id)}>
                                Leave Service Feedback
                            </button>
                            <button key={index} className="bg-[#FBF7F7] text-[16px]   text-black px-4 py-2 rounded border-[#D8D8D8] border-[1px]"
                                style={{ boxShadow: "0px 12px 16px -4px #00000014" }}>
                                Raise Concern
                            </button>
                            <button key={index} className="bg-[#FBF7F7] text-[16px]   text-black px-4 py-2 rounded border-[#D8D8D8] border-[1px]"
                                style={{ boxShadow: "0px 12px 16px -4px #00000014" }}>
                                Download Answer
                            </button>

                        </div>
                        <div className=" lg:flex-row lg:hidden flex w-full h-[6rem] justify-between items-center mt-3">
                            {buttonIcon.map((data, index) => (
                                <div key={index} className="flex flex-col ">
                                    <img
                                        src={data.img}
                                        className="bg-[#FBF7F7] w-[5rem] h-[4rem]   text-black px-4 py-2 rounded border-[#D8D8D8] border-[1px]"
                                        style={{ boxShadow: "0px 4px 4px 0px #0000001A" }} />
                                    <p className="max-w-[5rem] h-[1rem] text-center text-[9px] text-[#767677]">{data.label}</p>

                                </div>
                            ))
                            }
                        </div>
                    </div>
                </div>
            ))}

            <FeedbackForm
                open={isFeedbackOpen}
                handleClose={handleCloseFeedback}
                handleSubmit={handleSubmitFeedback}
                feedbackId={selectedFeedbackId}
            />
        </section>
    );
}

export default QueryCard;
