import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { motion } from 'framer-motion';
import { IoMdArrowRoundBack } from "react-icons/io";
import { LinearProgress } from '@mui/material';
import { Box, } from "@mui/material";
import SixthQuery from "../Components/Home/Querysix"
import Navbar from "../Components/Navbar";
import { addFilter, removeFilter } from '../Slices/filterSlice';
import { isTokenValid, isLoginRecent } from '../utils/function'
import { baseUrl } from "../utils/baseUrl";
import { step2, step3, step4, step5 } from "../data/HomepageData/queriesData";
import videoSource from '../assests/video/Fin_Right_Reel.mp4'
import Querythird from "../Components/Home/Querythird";
 

import { 
    addPersonalisedFilter, 
    removePersonalisedFilter, 
    clearPersonalisedFilter, 
    clearAllPersonalisedFilters 
  } from '../Slices/personaliseFilterSlice';


const Homepage = () => {
    const { step } = useParams();
    const [isInitialLoading, setIsInitialLoading] = useState(true);
    const [query, setQuery] = useState(parseInt(step) || 1);
    const [email, setEmail] = useState("");
    const [error, setError] = useState("");
    // const filters = useSelector((state) => state.selectFilters.filters);
    const personalisedFilters = useSelector((state) => state.selectPersonalisedFilters.personalisedFilters);

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const totalSteps = 6;
    const currentStep = parseInt(step || 1, 10);
    const progress = (currentStep / totalSteps) * 100;

    useEffect(() => {
        if (step) {
            setQuery(parseInt(step));
        }
    }, [step]);

    const validateEmail = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        if (!email) {
            return setError("Email is required.");
        }

        if (!validateEmail(email)) {
            return setError("Please enter a valid email address.");
        }

        try {
            const response = await fetch(`${baseUrl}/user/get-start`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({ email }),
            });

            const data = await response.json();

            if (!response.ok) {
                throw new Error(data.message || "Something went wrong.");
            }

            const { token, isDetailFilled, isSignupVerified, lastLogin } = data?.user || {};
            setEmail("")
            localStorage.setItem("token", token || "");
            localStorage.setItem("finright_email", email || "");
            localStorage.setItem("isDetailFilled", isDetailFilled);
            localStorage.setItem("user", data?.user ? JSON.stringify(data?.user) : "");

            // if (!isDetailFilled) {
            //     navigate("/2");
            // } else if (isSignupVerified && isTokenValid(token) && isLoginRecent(lastLogin)) {
            //     navigate("/2");
            // } else if (isSignupVerified && !isTokenValid(token)) {
            //     navigate("/user/login");
            // } else {
            //     navigate("/user/login");
            // }

            // by pass login
            navigate("/2");

        } catch (error) {
            console.error("Error during submission:", error.message);
            setError(error.message || "An unexpected error occurred.");
        }
    };



    const [cards, setCards] = useState(step2);
    const [selectedCardId, setSelectedCardId] = useState(null);

    const handleClick = (id) => {
        setSelectedCardId(id);
        setCards(cards.map(card => ({
            ...card,
            isClicked: card.id === id ? true : false,
        })));
    };

    const [cardStates, setCardStates] = useState(step3);

    const handleCardClick = (id, card) => {
        dispatch(addPersonalisedFilter({ filterType: "personality", value: card.title }));
        setCardStates(prevStates =>
            prevStates.map(card =>
                card.id === id ? { ...card, iSClicked: true } : { ...card, iSClicked: false }
            )
        );
    };

    const handleContinue = (redirect) => {
        const filterMap = {
            "/3": "personality",
            "/4": "goals",
            "/5": "profession"
        };

        const filterKey = filterMap[redirect];

        if (filterKey && personalisedFilters[filterKey].length <= 0) {
            alert("Please select any option");
        } else {
            navigate(redirect);
        }
    };

    const handleContinueClick = () => {
        if (selectedCardId === 1) {
            navigate("/5");
            window.localStorage.setItem("visitedThrough", "/2")
        } else if (selectedCardId === 2) {
            navigate("/3");
        } else {
            alert("Please select any one option")
        }
    };



    const [cardStepFour, setCardStepFour] = useState(step4);


    const handleCardClickFour = (id, card) => {
        setCardStepFour(prevStates =>
            prevStates.map(c =>
                c.id === id ? { ...c, isClickedFour: !c.isClickedFour } : c
            )
        );

        if (card.isClickedFour) {
            dispatch(removePersonalisedFilter({ filterType: "goals", value: card.title }));
        } else {
            dispatch(addPersonalisedFilter({ filterType: "goals", value: card.title }));
        }
    };

    const [cardStepFive, setCardStepFive] = useState(step5);

    const handleCardClickFive = (id, card) => {
        const previouslySelectedCard = cardStepFive.find(c => c.isClickedFive);

        if (previouslySelectedCard && previouslySelectedCard.id !== id) {
            dispatch(removePersonalisedFilter({ filterType: "profession", value: previouslySelectedCard.title }));
        }

        dispatch(addPersonalisedFilter({ filterType: "profession", value: card.title }));

        setCardStepFive(prevStates =>
            prevStates.map(c =>
                c.id === id ? { ...c, isClickedFive: true } : { ...c, isClickedFive: false }
            )
        );
    };

    const handleAlreadyAccount = () => {
        navigate('/user/login', { state: { reditectTo: "/2" } })
    }

    const resetSelectedCards = () => {
        setCardStepFour(step4.map(c => ({ ...c, isClickedFour: false })));
        setCardStepFive(step5.map(c => ({ ...c, isClickedFive: false })));
        setCardStates(step3.map(c => ({ ...c, iSClicked: false })));

    };

    return (
        <Box>
            <Navbar setQuery={setQuery} />
            <div style={{ width: '100%', marginBottom: '20px' }}>
                <LinearProgress variant="determinate" value={progress} />
            </div>
            {query === 1 && (
                <motion.div
                    initial={{ opacity: 0, y: 50 }}
                    animate={{ opacity: 1, y: 0 }}
                    exit={{ opacity: 0, y: -50 }}
                    transition={{ duration: 0.5 }}
                >
                    <section className="flex flex-col lg:h-[calc(100vh-9.5rem)] w-full lg:flex-row justify-evenly overflow-hidden mb-[2rem]">
                        <section className="flex flex-col-reverse md:flex-row w-full gap-[15px] mt-[2rem] px-[15px] h-full">
                            <div className="flex justify-center flex-col lg:gap-y-[1.5rem] gap-y-[1.25rem] text-center md:w-[50%] w-full mx-auto border-red-600">
                                <div>
                                    <h1 className="font-rich lg:text-[2.5vw] text-[2em] font-bold text-center text-richgreen-800 leading-37">
                                        Got Financial Queries?<br /> We got Answers
                                    </h1>
                                </div>
                                <p className="text-[1.2rem] lg:text-[1.2em] text-richgreen-50 font-semibold">
                                    Take control of your personal finance like never before
                                </p>
                                <form onSubmit={handleSubmit} className="flex flex-col lg:flex-row items-center justify-center gap-[15px] w-full">
                                    <input
                                        type="email"
                                        placeholder="Your Email Address"
                                        className="rounded-lg w-full lg:w-[42%] h-[2.75rem] text-base lg:text-[1rem] p-3 placeholder:text-sm"
                                        onChange={(e) => setEmail(e.target.value)}
                                        value={email}
                                    />
                                    <button
                                        className="w-full lg:w-[20%] py-[0.6rem] px-[1rem] text-base lg:text-[1rem] bg-richgreen-800 rounded-lg text-richgreen-5 font-inter"
                                        type="submit"
                                        style={{ border: "1px solid #0F5426" }}
                                    >
                                        Let's go!
                                    </button>
                                </form>

                                {error && <p className="text-red-500 text-sm">{error}</p>}
                                {/* <div className="w-full mx-auto lg:w-[33.125rem]">
                                    <p className="text-[#027A48]  text-[1.2rem] pl-[1rem] text-center font-semibold cursor-pointer" onClick={handleAlreadyAccount}>
                                        Already have an account?
                                    </p>
                                </div> */}
                            </div>
                            {/* <div className="flex justify-center items-center lg:h-[75vh] md:w-[50%] w-full h-[50vh] mx-auto rounded-2xl">
                                <img
                                    src={image1}
                                    className="lg:w-[100%] lg:h-[100%] w-[19rem] h-[100%] rounded-2xl object-cover"
                                    alt="Cover"
                                />
                            </div> */}
                            <div className="flex justify-center items-center lg:h-[75vh] md:w-[50%] w-full h-[50vh] mx-auto rounded-2xl">
                                <div className="w-[100%] h-[100%] md:w-[80%]">
                                    <video
                                        src={videoSource}
                                        className="lg:w-[100%] lg:h-[100%] w-full h-[100%] rounded-2xl object-covered"
                                        autoPlay
                                        loop
                                        muted
                                        controls
                                    />
                                </div>
                            </div>
                        </section>

                    </section>
                </motion.div>
            )}


            {query === 2 && (
                <motion.div
                    initial={{ opacity: 0, y: 50 }}
                    animate={{ opacity: 1, y: 0 }}
                    exit={{ opacity: 0, y: -50 }}
                    transition={{ duration: 0.5 }}
                >
                    <div className="relative">
                        <div className="absolute top-[0] lg:top-10 lg:left-10 top-5 left-5  flex items-center cursor-pointer" onClick={() => navigate("/1")}>
                            <IoMdArrowRoundBack
                                className="">

                            </IoMdArrowRoundBack>
                            <span className="lg:text-[1rem] text-[0.7rem] "></span>

                        </div>
                        <section className="container mx-auto min-h-[calc(100vh-11vh-10px-40px)]  w-full flex flex-col items-center justify-between px-[1rem] sm:px-[2rem]">
                            {/* Header Text */}
                            <div className="text-center max-w-[80vw] mb-[24px]">
                                <p className="text-richgreen-25 font-bold text-[1.1875rem] sm:text-[1.5rem] md:text-[2rem] lg:text-[2.5rem]">
                                    How can we help you?
                                </p>
                                <p className="text-[1rem] sm:text-[1.25rem] mt-[20px]">
                                    Choose any of the objectives.
                                </p>
                            </div>
                            

                            {/* Cards */}
                            <div className="w-full md:max-w-full md:w-auto grid grid-cols-1 sm:grid-cols-1 gap-[1rem] lg:gap-[2.5rem]">

                                {/* <div className="flex flex-wrap gap-[15px] lg:gap-x-[70px] justify-center"> */}
                                {cards.map((card, index) => (
                                    <div key={index} className="flex  justify-center">
                                        <div
                                            key={card.id} 
                                            onClick={() => handleClick(card.id)}
                                            className={`border-[1px] h-[75px] w-full sm:w-[100%] md:w-[100%] lg:w-[100%] xl:w-[30rem] h-[4rem] border-richgray-25 flex items-center gap-x-[0.5rem] rounded-xl p-[0.25rem] pl-[0.5rem] lg:pl-[1.25rem] cursor-pointer transition-all ease-in ${card.isClicked ? 'bg-richgreen-25 text-white' : 'bg-white text-black'} hover:shadow-lg`}
                                        >
                                            <img
                                                src={`${card.isClicked ? card.iconL : card.iconD}`}
                                                className={`w-[2.5rem] lg:w-[2rem] aspect-square ${card.isClicked ? 'text-white' : 'bg-white'}`}
                                                alt={card.title}
                                            />
                                            <p className="text-[0.875rem] sm:text-[1rem] lg:text-[1.4rem]">{card.text}</p>
                                        </div>
                                    </div>
                                ))}
                            </div>

                            {/* Continue Button */}
                            <div className="flex justify-center mt-[10px] w-full sticky bottom-[20px]">
                                <button
                                    onClick={handleContinueClick}
                                    className="p-[10px] left-1/2 text-[1.125rem] sm:text-[1.25rem] lg:text-[1rem] w-full max-w-[14.95rem] h-[3.125rem] sm:h-[3.75rem] lg:h-[3.3rem] text-richgreen-5 font-inter bg-richgreen-800 rounded-xl shadow-lg"
                                >
                                    Continue
                                </button>
                            </div>

                        </section>
                    </div>
                </motion.div>
            )}

           
            {query === 3 && (
                <Querythird
                    setQuery={setQuery}
                    onGoBack={resetSelectedCards}
                    step={step}
                    cardStepFour={cardStepFour}
                    handleCardClickFour={handleCardClickFour}
                    handleContinue={handleContinue}
                />

            )}

            {
                query === 4 && (
                    <motion.div
                        initial={{ opacity: 0, y: 50 }}
                        animate={{ opacity: 1, y: 0 }}
                        exit={{ opacity: 0, y: -50 }}
                        transition={{ duration: 0.5 }}
                    >
                        <div className="relative" >
                            <div className="absolute top-[0] lg:top-10 lg:left-10 flex items-center cursor-pointer " onClick={() => navigate("/3")}>
                                <IoMdArrowRoundBack className="cursor-pointer"></IoMdArrowRoundBack>
                                {/* <span className="lg:text-[1rem] text-[0.6rem]">Back</span> */}
                            </div>

                            <section className="container mx-auto min-h-[calc(100vh-11vh-10px-40px)] w-full flex flex-col items-center justify-between px-[1rem] sm:px-[2rem]">
                                {/* Header Section */}

                                <div className="text-center max-w-[80vw] mb-[24px]">
                                    <p className="text-richgreen-25 font-bold text-[1.1875rem] sm:text-[1.5rem] md:text-[2rem] lg:text-[2.5rem]">
                                        How do you identify yourself?
                                    </p>
                                    <div className="flex lg:flex-col items-center gap-[0.5rem] flex-row-reverse">
                                        <p className="text-[1rem] sm:text-[1.25rem]">
                                            Choose whatever relates to you the most.
                                        </p>
                                        {/* <img src={star} className="w-[2.5rem] sm:w-[3.125rem] lg:w-[3.75rem] aspect-square" alt="Star Icon" /> */}
                                    </div>
                                </div>


                                <div className="w-full md:max-w-full md:w-auto md:w-auto grid grid-cols-1 sm:grid-cols-2 gap-[1rem] lg:gap-[2vw]">
                                    {cardStepFive.map((card, index) => (
                                        <div key={index} className="flex justify-center">
                                            <div
                                                onClick={() => handleCardClickFive(card.id, card)}
                                                className={`border-[1px] h-[75px] w-full sm:w-[100%] md:w-[100%] lg:w-[100%] xl:w-[30rem] h-[4rem] border-richgray-25 flex items-center gap-x-[0.5rem] rounded-xl p-[0.25rem] pl-[0.5rem] lg:pl-[1.25rem] cursor-pointer ${card.isClickedFive ? 'bg-richgreen-25 text-white' : 'bg-white text-black'} hover:shadow-lg`}
                                            >
                                                <img
                                                    src={card.isClickedFive ? card.iconL : card.iconD}
                                                    className="w-[2.5rem] sm:w-[3.125rem] lg:w-[2rem] aspect-square"
                                                    alt={card.title}
                                                />
                                                <div>
                                                    <p className="text-[0.875rem] sm:text-[1rem] lg:text-[1.4rem]">{card.title}</p>

                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>

                                {/* Continue Button */}
                                <div className="flex justify-center mt-[10px] w-full sticky bottom-[20px]">
                                    <button
                                        onClick={() => {
                                            window.localStorage.setItem("visitedThrough", "/3")
                                            handleContinue("/5")
                                        }}
                                        className="p-[10px] left-1/2 text-[1.125rem] sm:text-[1.25rem] lg:text-[1rem] w-full max-w-[14.95rem] h-[3.125rem] sm:h-[3.75rem] lg:h-[3.3rem] text-richgreen-5 font-inter bg-richgreen-800 rounded-xl shadow-lg"
                                    >
                                        Continue
                                    </button>
                                </div>

                            </section>
                        </div>
                    </motion.div>
                )
            }
            {
                query === 5 && (
                    <motion.div
                        initial={{ opacity: 0, y: 50 }}
                        animate={{ opacity: 1, y: 0 }}
                        exit={{ opacity: 0, y: -50 }}
                        transition={{ duration: 0.5 }}
                    >
                        <SixthQuery step={step} setQuery={setQuery} onGoBack={resetSelectedCards} />
                    </motion.div>
                )
            }
        </Box>

    );
};

export default Homepage;