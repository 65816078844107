import React, { useState } from "react";
import Admin from "../assests/Login_imgs/loginAdmin.png"
import { useNavigate } from "react-router-dom";
import { baseUrl } from "../utils/baseUrl";
const AdminLogin = () => {
    const navigate=useNavigate()
    const [error, setError] = useState("");
    const [mobile, setMobile] = useState()
    const [password, setPassword] = useState()

    const handleSubmit = async () => {
        try {
            const response = await fetch(`${baseUrl}/admin/login`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ mobile: mobile, password: password }),
            });

            const data = await response.json();
            if (response.ok) {
                const admin={
                    token:data.token,
                    role:data.user.role
                }
                window.localStorage.setItem("admin_token",admin ? JSON.stringify(admin) : "")
                navigate("/cms/panel/manage-query")
            }
        } catch (error) {
            console.error(`Failed to upload answer. ${error?.message}`);
        }
    }

    return (
        <div className="w-[100vw] h-[100vh] grid grid-cols-2 bg-white">
            <div className="grid place-content-center">

                <div className="flex justify-center items-center h-screen font-sfPro ">
                    <div className="w-[400px] bg-white p-8 ">
                        <h1 className="text-3xl font-bold text-[#0C1421]  mb-4">
                            Welcome Back <span role="img" aria-label="wave">👋</span>
                        </h1>
                        <p className=" text-[#313957] mb-8">
                            Today is a new day. It's your day. You shape it.
                            <br />
                            Sign in to start managing your projects.
                        </p>

                        <div className="mb-4">
                            <label className="block text-[#0C1421] mb-2">Mobile number</label>
                            <input
                                type="text"
                                placeholder="Enter mobile number"
                                onChange={(e) => setMobile(e.target.value)}
                                className="w-full px-4 py-2 border rounded-md bg-gray-100 focus:outline-none focus:ring-2 focus:ring-blue-500"
                            />
                        </div>

                        <div className="mb-4">
                            <label className="block text-[#0C1421] mb-2">Password</label>
                            <input
                                type="password"
                                placeholder="At least 8 characters"
                                onChange={(e) => setPassword(e.target.value)}
                                className="w-full px-4 py-2 border rounded-md bg-gray-100 focus:outline-none focus:ring-2 focus:ring-blue-500"
                            />
                            <div className="text-right mt-2">
                                <a href="#" className="text-[#1E4AE9] text[1rem]">Forgot Password?</a>
                            </div>
                        </div>

                        <button onClick={handleSubmit} className="w-full bg-[#006BFF] text-white py-2 rounded-md hover:bg-blue-600 transition-colors">
                            Sign in
                        </button>

                        <p className="text-center text-gray-600 mt-8">
                            Don't have an account? <a href="#" className="text-blue-500">Sign up</a>
                        </p>
                    </div>
                </div>
            </div>
            <div className="w-full grid place-content-center">
                <img
                    src={Admin}
                    className=" h-[90vh] w-full object-cover"
                    alt="Cover"
                />
            </div>

        </div>
    )
}
export default AdminLogin;