import React, { useEffect, useState } from 'react'
import EditableTable from '../../Components/Dashbaoard/EditableTable'
import { baseUrl } from '../../utils/baseUrl';
const ManageQuery = () => {
    const [data, setData] = useState()
    const token = window.localStorage.getItem("token")



    const fetAllQueryData = async () => {
        try {
            const response = await fetch(`${baseUrl}/admin/get-all-query-list`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
            });
            const data = await response.json();
            if (!response.ok) {
                throw new Error(data?.message || "Data not found")
            }
            setData(data)
        } catch (error) {
            console.error({ error: error.message || "Internal server Error" })
        }
    }

    const handleUpdate = async (id, field, value) => {
        console.log(`Updating field: ${field} with value: ${value} for id: ${id}`);
        try {
            const bodyData = {
                [field]: value
            };
            const url = `${baseUrl}/admin/update-search-page-query/${id}`
            const response = await fetch(url, {
                method: 'PATCH',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
                body: JSON.stringify(bodyData)
            });
            const data = await response.json();
            if (!response.ok) {
                throw new Error(data?.message || "Data not found")
            }
            fetAllQueryData()
        } catch (error) {
            console.error({ error: error.message || "Internal server Error" })
        }
    };


    useEffect(() => {
        fetAllQueryData()
    }, [])
    return (
        <div>
            {data && <EditableTable data={data} onUpdate={handleUpdate} />}
        </div>
    )
}

export default ManageQuery
