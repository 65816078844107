import './App.css';
import React from 'react';
import { Routes, Route, Navigate } from "react-router-dom";
import { UserProvider } from './Context/UserContext';
import { isUserRegistered } from './utils/function';
import Homepage from './Pages/HomePage';
import Signup from './Pages/SignupForm';
import VerifyOtp from './Pages/verifyOtp';
import SignIn from './Pages/Sign_In';
import Dashbaoard from './Pages/Dashboard';
import { Protected } from './Components/Auth/OpenRoute';
import Profile from './Components/Dashbaoard/ProfileDash';
import QueryEight from './Components/Home/QueryEight';
import TakeExpert from './Pages/TakeExpert';
import AdminDashboard from './Pages/AdminDashboardPages/AdminDashboard';
import UserList from './Pages/AdminDashboardPages/UsersList';
import UserDashboard from './Pages/UserDashboard';
import ScheduleMeeting from './Pages/ScheduleMeeting';
import AdminLogin from './Pages/AdminLogin';



import AddAnswer from './Pages/AdminPanel/AddAnswer';
import ScheduleWidget from './Calendy/ScheduleAppointment';
import ManageQuery from './Pages/AdminPanel/ManageQuery';
import Layout from './Layout/AdminLayout';
import UploadQueryData from './Pages/AdminPanel/UploadQueryData';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import FilterEditor from './Pages/AdminPanel/FilterEditor';
import DeleteQuestion from './Pages/AdminPanel/DeleteQuestion';

const theme = createTheme();
function App() {
  return (
    <ThemeProvider theme={theme}>
      <div className="App bg-richgreen-5">
        <UserProvider>

          <Routes>

            <Route path="/" element={<Homepage />} />
            <Route path="/schedule-appointment" element={<ScheduleWidget />} />
            <Route path="/:step" element={<Homepage />} />
            <Route path='/user/signup' element={<Signup />}> </Route>
            <Route path='/user/login' element={<SignIn />}></Route>
            <Route path='/schedule-meeting' element={<ScheduleMeeting />}></Route>
            <Route path='/user/verify/login-otp' element={<VerifyOtp />} />
            {/* <Route path='/queries' element={<Protected><QueryEight /></Protected>}></Route>
            <Route path='/take-expert' element={<Protected> <TakeExpert /> </Protected>}></Route> */}

            <Route path='/queries' element={<QueryEight />}></Route>
            <Route path='/take-expert' element={<TakeExpert />}></Route>
            
            <Route element={<Protected><Dashbaoard /></Protected>}>
              <Route path='/dashboard/profile' element={<Protected><Profile /></Protected>}></Route>
              <Route path='/dashboard/profile' element={<Protected><Profile /></Protected>}></Route>
            </Route>

            {/* <Route path="/cms/panel" element={<Protected><Dashbaoard /></Protected>}>
            <Route path='admin/dashboard' element={<AdminDashboard />}> </Route>
          </Route> */}

            <Route path="/cms/panel" element={<Layout />}>
              <Route path='add-answer' element={<AddAnswer />}></Route>
              <Route path='remove-add-filter' element={<FilterEditor />}></Route>
              <Route path='manage-query' element={<ManageQuery />}></Route>
              <Route path='delete-question' element={<DeleteQuestion />}></Route>
              <Route path='admin/manage-Q&A' element={<></>}> </Route>
              <Route path='upload-question' element={<UploadQueryData />}> </Route>
              <Route path='admin/dashboard' element={<AdminDashboard />}> </Route>
            </Route>

            <Route path='/cms/panel/admin/login.dev' element={<AdminLogin className />}></Route>


            <Route path='/admin/Users' element={<UserList />}> </Route>
            <Route path='/user/dashboard' element={<UserDashboard />}> </Route>


          </Routes>
        </UserProvider>
      </div>
    </ThemeProvider>
  );
}

export default App;
