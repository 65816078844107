import React ,{useState} from 'react'
import { sideBarLink } from "../../data/HomepageData/queriesData";
import { useSelector, useDispatch } from 'react-redux';
import { addFilter, removeFilter, clearAllFilters } from '../../Slices/filterSlice';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';


const SideBarLayout = ({handleCollapse,filters,setSearchText,isCollapse,}) => {
    const dispatch = useDispatch();


    return (
        <div className="hidden lg:block w-[30%] h-[100%] overflow-y:auto  shadow-[0_100px_80px_#00000033] bg-white rounded-lg p-1 transition-all duration-500 ease-in-out"
            style={{
                overflowY: "auto",
                scrollbarWidth: "none",
                msOverflowStyle: "none",
            }}>
            {sideBarLink.map((section, index) => (
                <div key={index} className="m-1 h-auto border-b-[1px] border-b-[#dadada]">
                    <button
                        className="flex justify-between items-center w-full  p-[0.25em] bg-white rounded-lg font-semibold transition-all duration-500 ease-in-out"
                        onClick={() => handleCollapse(index)}
                    >
                        <span className="text-[1.2rem]">{section.title.label}</span>
                        <KeyboardArrowDownIcon />
                    </button>
                    <div
                        className={`transition-max-height  duration-500 ease-in-out ${isCollapse[index] ? 'max-h-[500px]' : 'max-h-0 overflow-hidden'}`}
                    >
                        <div className="rounded-lg mt-1 p-1">
                            {section.labels.map((label, labelIndex) => (
                                <label key={labelIndex} className="block text-[1rem]">
                                    <input
                                        type="checkbox"
                                        className="mr-2"
                                        onChange={(e) => {
                                            const value = label.value; // Filter value
                                            const filterType = section.title.value; // Filter type
                                            setSearchText("")
                                            if (e.target.checked) {
                                                dispatch(addFilter({ filterType, value }));
                                            } else {
                                                dispatch(removeFilter({ filterType, value }));
                                            }
                                        }}
                                        checked={filters[section.title.value]?.includes(label.value) || false}
                                    />
                                    {label.title}
                                </label>
                            ))}
                        </div>
                    </div>
                </div>
            ))}
        </div>
    )
}

export default SideBarLayout
