import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  personalisedFilters: {
    category: [],
    goals: [],
    profession: [],
    financialStatus: [],
    experience: [],
    personality: [],
  },
}; 

const personalisedFiltersSlice = createSlice({
  name: 'personalisedFilters',
  initialState,
  reducers: {
    addPersonalisedFilter: (state, action) => {
      const { filterType, value } = action.payload;

      if (filterType === 'personality') {
        state.personalisedFilters.personality = [value];
      } else {
        if (!state.personalisedFilters[filterType].includes(value)) {
          state.personalisedFilters[filterType].push(value);
        }
      }
    },
    removePersonalisedFilter: (state, action) => {
      const { filterType, value } = action.payload;
      state.personalisedFilters[filterType] = state.personalisedFilters[filterType].filter(
        (item) => item !== value
      );
    },
    clearPersonalisedFilter: (state, action) => {
      const { filterType } = action.payload;
      state.personalisedFilters[filterType] = [];
    },
    clearAllPersonalisedFilters: (state) => {
      Object.keys(state.personalisedFilters).forEach((filterType) => {
        state.personalisedFilters[filterType] = [];
      });
    },
  },
});

export const {
  addPersonalisedFilter,
  removePersonalisedFilter,
  clearPersonalisedFilter,
  clearAllPersonalisedFilters,
} = personalisedFiltersSlice.actions;

export default personalisedFiltersSlice.reducer;
