import React from "react";
import { useSelector } from "react-redux";

const Profile = () => {

    // const { user } = useSelector((state) => state.profile)
    // console.log("USer Profile data", user)
    return (
        <div>
            {/* logout bottons */}
            <div className="flex flex-row  justify-end w-[80%] gap-5">
                <button className="text-[#313D4F] text-[20px]">Logout</button>
                <button className="bg-[#006BFF] text-[18px] rounded-xl py-3 px-5 text-white ">Ask More questions</button>
            </div>
            <div className=" relative  flex min-h-[calc(100vh-3.5rem)] ">

                <div className="h-[calc(100vh-3.5rem)] flex-1 overflow-auto ">
                    <div className="mx-auto w-11/12 max-w-[1000px] border-2 py-10 ">

                    </div>
                </div>

            </div>

        </div>
    )
}
export default Profile
