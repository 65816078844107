import React, { useState, useEffect } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Editor } from '@tinymce/tinymce-react';
import { baseUrl } from '../../utils/baseUrl';
import axios from 'axios';

const AddAnswer = () => {
  const [answer, setAnswer] = useState('');
  const [question, setQuestion] = useState();
  const [questionList, setQuestionList] = useState([]);
  const [errors, setErrors] = useState({ question: '', answer: '' });
  const [selectedQuestion, setSelectedQuestion] = useState('');


  useEffect(() => {
    const fetchQuestions = async () => {
      try {
        const response = await axios.get(`${baseUrl}/admin/question-list`);
        console.log("response", response)
        setQuestionList(response?.data?.questions);
      } catch (error) {
        console.error('Error fetching questions:', error);
      }
    };

    fetchQuestions();
  }, []);

  const validateInputs = () => {
    let isValid = true;
    const newErrors = { question: '', answer: '' };

    if (!selectedQuestion) {
      newErrors.question = 'Question is required.';
      isValid = false;
    }

    if (!answer.trim()) {
      newErrors.answer = 'Answer is required.';
      isValid = false;
    }

    setErrors(newErrors);
    return isValid;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validateInputs()) {
      return;
    }

    const token = window.localStorage.getItem('token');
    try {
      const response = await fetch(`${baseUrl}/admin/add-answer-to-question/${selectedQuestion}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify({ answer: answer }),
      });

      const data = await response.json();
      if (response.ok) {
        toast.success('Answer uploaded successfully');
        window.location.reload();
      } else {
        toast.error(`Failed to upload answer. ${data?.message}`);
      }
    } catch (error) {
      toast.error(`Failed to upload answer. ${error?.message}`);
    }
  };



  return (
    <div className="container mx-auto mt-10">
      <ToastContainer />
      <div className="bg-white shadow-lg rounded-lg p-6">
        <h1 className="text-2xl font-bold mb-4">Upload New Answer</h1>
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <div className="mb-4">
              <label className="block text-sm font-medium text-gray-700 mb-2" htmlFor="question">
                Question
              </label>
              <select
                id="question"
                value={selectedQuestion}
                onChange={(e) => setSelectedQuestion(e.target.value)}
                className={`block w-full border ${errors.question ? 'border-red-500' : 'border-gray-300'} rounded-lg p-2 focus:outline-none focus:ring focus:ring-blue-500`}
                required
              >
                <option value="" disabled>Select a question</option>
                {questionList && questionList.map((q) => (
                  <option key={q._id} value={q._id}>
                    {q.question}
                  </option>
                ))}
              </select>
              {errors.question && <p className="text-red-500 text-sm mt-1">{errors.question}</p>}
            </div>
          </div>
          <div className="mb-4">
            <label className="block text-sm font-medium text-gray-700 mb-2" htmlFor="answer">
              Answer
            </label>
            <Editor
              apiKey="wpc3883gy9wwm18yhjxowq2ic75qi67u0b0h3vi9eydn69mm"
              init={{
                height: 500,
                menubar: true,
                plugins: [
                  'advlist autolink lists link image charmap print preview anchor',
                  'searchreplace visualblocks code fullscreen',
                  'insertdatetime media table paste code help wordcount',
                ],
                toolbar:
                  'undo redo | formatselect | bold italic backcolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat | help | table',
                images_upload_url: `${baseUrl}/blog/upload-image`,
                file_picker_types: 'image',
                images_upload_handler: (blobInfo, success, failure) => {
                  const data = new FormData();
                  data.append('file', blobInfo.blob(), blobInfo.filename());
                  fetch(`${baseUrl}/blog/upload-image`, {
                    method: 'POST',
                    body: data,
                  })
                    .then((response) => response.json())
                    .then((result) => success(result.fileUrl))
                    .catch(() => failure('Image upload failed.'));
                },
              }}
              onEditorChange={(content) => {
                setAnswer(content);
                if (content.trim()) {
                  setErrors((prev) => ({ ...prev, answer: '' }));
                }
              }}
            />
            {errors.answer && <p className="text-red-500 text-sm mt-1">{errors.answer}</p>}
          </div>

          <button
            type="submit"
            className="w-full bg-blue-500 text-white font-semibold py-2 rounded-lg hover:bg-blue-600"
          >
            Submit
          </button>
        </form>
      </div>
    </div>
  );
};

export default AddAnswer;
