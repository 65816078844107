import assistance from "../assests/Login_imgs/assistance.svg"
import concern from "../assests/Login_imgs/concern.svg"
import Download from "../assests/Login_imgs/download.svg"


export const buttonData = [
    { label: "Book Assistance", bgColor: "bg-pink-500" },
    { label: "Leave Service Feedback", bgColor: "bg-gray-300" },
    { label: "Raise Concern", bgColor: "bg-gray-300" },
    { label: "Download Answer", bgColor: "bg-gray-300" }
];


 export const buttonIcon = [
    { label: "Book Assistance", img: assistance, },
    { label: "Leave Service Feedback", img: concern, },
    { label: "Raise Concern", img: concern, },
    { label: "Download Answer", img: Download, }
]