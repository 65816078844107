import React, { useState } from "react";
import { GoStopwatch } from "react-icons/go";
import { FiPhone } from "react-icons/fi";
import Congratulations from "./congraulation";
import dayjs from 'dayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import { Modal, Box } from "@mui/material";
import {useDispatch } from 'react-redux';
import {resetState} from '../../Slices/askQuerySlice';

const ExpertAssistance = () => {
  const [value, setValue] = useState(dayjs('2022-04-17'));
  const [schedule, setSchedule] = useState(false);
  const dispatch = useDispatch();
  
  const handleCloseOpen = () => {
    setSchedule(true);
    dispatch(resetState());
  };

  const handleClose = () => {
    setSchedule(false);
  };

  return (
    <div
      style={{ boxShadow: "0px 4px 4px 5px #00000040" }}
      className="absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] w-[1300px] h-[950px] bg-blue-100 flex flex-col items-center justify-center px-10 rounded-lg"
    >
      <header className="text-center mb-5">
        <h1 className="text-7xl font-bold mb-10">Take Expert Assistance</h1>
      </header>

      <div className="flex flex-col md:flex-row md:justify-between lg:justify-evenly w-full p-10">
        {/* Left Section */}
        <div className="flex w-[80%] h-[600px] border-2 border-blue-500 bg-white p-5 ">
          <div className="w-[50%] h-full flex justify-between flex-col p-5">
            <div className="w-[100%]">
              <h2 className="text-lg font-bold mb-2">
                Resolution to Rahul's Inquiry Queue
              </h2>
              <div className="flex items-center gap-x-2 text-gray-400 font-semibold">
                <GoStopwatch className="w-[25px] h-[25px]" />
                <p className="text-[20px]">30 min</p>
              </div>
              <div className="flex items-center gap-x-2 text-gray-400 font-semibold">
                <FiPhone className="w-[25px] h-[25px]" />
                <p className="text-[20px]">Phone Call</p>
              </div>
              <p className="text-[16px] mt-6 text-gray-600">
                A dedicated FinRight Expert will reach out to you to understand your
                queries and provide the best possible solution.
              </p>
            </div>
            <div className="text-sm text-gray-500 mt-2 flex justify-between">
              <a href="#" className="mr-4 text-blue-400">
                Cookie settings
              </a>
              <a href="#">Report abuse</a>
            </div>
          </div>

          {/* Middle Section with DateCalendar */}
          <div className="flex flex-col items-start gap-x-10  w-[50%] justify-between p-5">
            <h3 className="text-lg font-semibold  pl-10 ">Select Date & Time</h3>
            <LocalizationProvider dateAdapter={AdapterDayjs} className="w-full">
              <DateCalendar
                value={value}
                onChange={(newValue) => setValue(newValue)}
              />
            </LocalizationProvider>
            <div className="text-start pl-10">
              <h1 className="text-[20px] font-semibold">Time Zone</h1>
              <p>Central Europeon Time(8:10)</p>
            </div>
            <button
              className="py-3 border-2 ml-10  border-[#0F5426] font-inter  rounded-2xl text-[21px] px-10 text-[#F2FFF3] bg-[#045172]"
              onClick={handleCloseOpen}>
              Schedule Call

            </button>
          </div>
        </div>

        {/* Right Section for time selection */}
        <div className="w-[20%] flex flex-col ml-10">
          <h3 className="text-lg font-medium  text-center">Choose Time</h3>
          <div className="flex space-x-2">
            <input
              type="text"
              className="w-16 p-4 text-center border rounded-md text-[28px]"
              defaultValue="12"
            />
            <input
              type="text"
              className="w-16 p-4 text-center border rounded-md text-[28px]"
              defaultValue="00"
            />
            <input
              type="text"
              className="w-16 p-4  px-2 text-center border rounded-md text-[24px]"
              defaultValue="AM"
            />
          </div>
        </div>
      </div>

      {/* Schedule Confirmation Modal */}
      {/* {Schedule && (
        <Congratulations setSchedule={setSchedule} setAssistance={setAssistance} />
      )} */}
      <Modal open={schedule} onClose={handleClose}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",          
            left: "50%",          
            transform: "translate(-50%, -50%)",
            maxWidth: "1000px",
            minWidth: "375px",
            width: "90%",
            backgroundColor: "white",
            borderRadius: "10px",
            boxShadow: 24,
            mx: "auto",
            overflow: "auto",
          }}
        >
          <Congratulations setSchedule={setSchedule} />
        </Box>
      </Modal>
    </div>
  );
};

export default ExpertAssistance;
