import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  filters: {
    category: [],
    goals: [],
    profession: [],
    financialStatus: [],
    experience: [],
    personality: [], 
  },
}; 

const filtersSlice = createSlice({
  name: 'filters',
  initialState,
  reducers: {
    addFilter: (state, action) => {
      const { filterType, value } = action.payload;

      if (filterType === 'personality') {
        state.filters.personality = [value];
      } else { 
        if (!state.filters[filterType].includes(value)) {
          state.filters[filterType].push(value);
        }
      }
    },
    removeFilter: (state, action) => {
      const { filterType, value } = action.payload;
      state.filters[filterType] = state.filters[filterType].filter(item => item !== value);
    },
    clearFilter: (state, action) => {
      const { filterType } = action.payload;
      state.filters[filterType] = [];
    },
    clearAllFilters: (state) => {
      Object.keys(state.filters).forEach(filterType => {
        state.filters[filterType] = [];
      });
    },
  },
});

export const { addFilter, removeFilter, clearFilter, clearAllFilters } = filtersSlice.actions;

export default filtersSlice.reducer;
