
// const host="http://65.0.223.11"
const host="http://localhost:3000"
const getAuthorizationCode = () => {
    const url = `https://auth.calendly.com/oauth/authorize?client_id=DE9byLemb8tFGU1HR7ln3j8zlklfnkXFwM0CMatukFY&code_challenge_method=S256&redirect_uri=${host}/schedule-meeting&response_type=code`;

    window.location.href = url;
};



const getAccessToken = async (code) => {
    const encodedParams = new URLSearchParams();
    encodedParams.append('grant_type', 'authorization_code');
    encodedParams.append('code', code);
    encodedParams.append('redirect_uri', `${host}/schedule-meeting`);

    const url = 'https://auth.calendly.com/oauth/token';
    const clientId = 'DE9byLemb8tFGU1HR7ln3j8zlklfnkXFwM0CMatukFY';
    const clientSecret = 'Cvwomyn01S7NUuhSWlNugih6rAZO4891BgBFD_KUmnc';
    const basicAuth = btoa(`${clientId}:${clientSecret}`);

    const options = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            'Authorization': `Basic ${basicAuth}`
        },
        body: encodedParams
    };

    try {
        const response = await fetch(url, options);
        const data = await response.json();
        console.log(data);
        return data;
    } catch (error) {
        console.error('error:', error);
    }
};



const getUser = async (token) => {
    try {

        let url = 'https://api.calendly.com/users/me';

        let options = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            }
        };

        const response = await fetch(url, options)
        const data = await response.json();
        console.log(data);
        return data;
    } catch (error) {
        console.error('error:', error);

    }

}


const getWebHook=async()=>{
    try {
        const options = {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              Authorization: 'Bearer eyJraWQiOiIxY2UxZTEzNjE3ZGNmNzY2YjNjZWJjY2Y4ZGM1YmFmYThhNjVlNjg0MDIzZjdjMzJiZTgzNDliMjM4MDEzNWI0IiwidHlwIjoiSldUIiwiYWxnIjoiRVMyNTYifQ.eyJpc3MiOiJodHRwczovL2F1dGguY2FsZW5kbHkuY29tIiwiaWF0IjoxNzI3MDkxMzgzLCJqdGkiOiJlOGQyNzgyMy0xZGYzLTRlNmQtYWJjMi04YWI4OTA5YjFlNzQiLCJ1c2VyX3V1aWQiOiIzZWQ5MmY5OS05MDJiLTQ5N2YtYmNjZi04Y2M4MzQyNGVkMzQiLCJhcHBfdWlkIjoiREU5YnlMZW1iOHRGR1UxSFI3bG4zajh6bGtsZm5rWEZ3TTBDTWF0dWtGWSIsImV4cCI6MTcyNzA5ODU4M30.eCAA1mxfEG2bnSIxMDV5kgcWLMC30VVyhy_GqV02ZWEStjDrm3PM-1t0GIETpd-oGW0eRyu1xXeO9kT9iHDnQQ'
            }
          };
          
          fetch('https://api.calendly.com/webhook_subscriptions?organization=https%3A%2F%2Fapi.calendly.com%2Forganizations%2F181c3b30-d40f-4c4a-a16c-14e0659430a1&user=https%3A%2F%2Fapi.calendly.com%2Fusers%2F3ed92f99-902b-497f-bccf-8cc83424ed34&scope=user', options)
            .then(response => response.json())
            .then(response => console.log(response))
            .catch(err => console.error(err));


    } catch (error) {
        
    }
}
module.exports = {
    getAuthorizationCode: getAuthorizationCode,
    getAccessToken: getAccessToken,
    getUser:getUser
}