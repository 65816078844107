import React from "react";
import { PopupWidget } from "react-calendly";

const ScheduleAppointment = () => {
  return (
    <div className="App">
      {/* <PopupWidget
        url="https://calendly.com/your_scheduling_page"
        rootElement={document.getElementById("root")}
        text="Click here to schedule!"
        textColor="#ffffff"
        color="#00a2ff"
      /> */}
      <iframe width='100%' height='750px' src='https://finright.zohobookings.com/portal-embed#/4690821000000041016' frameborder='0' allowfullscreen='' > </iframe>
    </div>
  );
};

export default ScheduleAppointment;